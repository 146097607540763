// File: src/components/PostItem/PostItem.js

import React, { useContext, useState, useEffect, useRef } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import BoltIcon from "@mui/icons-material/Bolt";
import CommentIcon from "@mui/icons-material/Comment";
import { UserContext } from "../../context/userContext";
import {
  format,
  formatDistanceToNow,
  differenceInHours,
  isValid,
  parseISO,
} from "date-fns";
import { likeBusiness, getLikeCount } from "../../utils/apiFunctions";
import dummyImg from "../../assets/dummyProfile.jpg";
import dummyImgPost from "../../assets/noimage.jpg";
import VerifiedBadge from "./VerifiedBadge";
import CommentSection from "./CommentSection";
import RatingBox from "./RatingBox";
import { Dialog, DialogContent, } from "@mui/material";

const PostItem = ({ post }) => {
  const [expandedPosts, setExpandedPosts] = useState({});
  const [visibleBox, setVisibleBox] = useState(null);
  const [likes, setLikes] = useState(post.likes.length);
  const [liked, setLiked] = useState(false);
  const { user } = useContext(UserContext);
  const commentBoxRef = useRef(null);

  const [isRatingDialogOpen, setIsRatingDialogOpen] = useState(false);

  const handleBoxVisibility = (boxType) => {
    setVisibleBox((prev) => (prev === boxType ? null : boxType));
  };

  const handleLike = async (id) => {
    if (user) {
      try {
        const formattedId = id.trim();
        const action = liked ? "unlike" : "like";
        await likeBusiness(formattedId, user._id, action);
        setLiked(!liked);
        const response = await getLikeCount(post._id);
        setLikes(response.like_count);
      } catch (error) {
        console.error("Error liking/unliking the business:", error);
      }
    }
  };

  useEffect(() => {
    if (user && post.likes.includes(user._id)) {
      setLiked(true);
    } else {
      setLiked(false);
    }
  }, [post.likes, user]);

  useEffect(() => {
    const fetchLikeCount = async () => {
      try {
        const response = await getLikeCount(post._id);
        setLikes(response.like_count);
      } catch (error) {
        console.error("Error fetching like count:", error);
      }
    };

    fetchLikeCount();
  }, [liked, post._id]);

  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".interaction-box") &&
      !event.target.closest(".interaction-button")
    ) {
      setVisibleBox(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (visibleBox === "comment" && commentBoxRef.current) {
      commentBoxRef.current.scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        window.scrollBy({ top: window.innerHeight * 0.1, behavior: "smooth" });
      }, 200);
    }
  }, [visibleBox]);

  const toggleDescription = (id) => {
    setExpandedPosts((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const isExpanded = expandedPosts[post._id];

  const postDate = parseISO(post.post_date);
  const now = new Date();

  let formattedDate = "Invalid date";
  if (isValid(postDate)) {
    const hoursDifference = differenceInHours(now, postDate);
    formattedDate =
      hoursDifference < 24
        ? formatDistanceToNow(postDate, { addSuffix: true })
        : format(postDate, "MMMM d, yyyy");
  }

  const handleRatingDialogOpen = () => {
    setIsRatingDialogOpen(true);
  };

  const handleRatingDialogClose = () => {
    setIsRatingDialogOpen(false);
  };

  return (
    <div className="w-full bg-white mb-6 flex flex-col rounded-xl shadow-lg">
      <div className="w-full flex items-center justify-between p-4 border-b">
        <div className="flex items-center">
          {post.logo ? (
            <img
              src={post.logo}
              alt="Profile"
              className="rounded-full w-12 h-12 mr-4"
            />
          ) : (
            <img
              src={dummyImg}
              alt="Profile"
              className="rounded-full w-12 h-12 mr-4"
            />
          )}
          <div>
            <h2 className="font-bold">{post.name}</h2>
            <p className="text-xs text-gray-500">{formattedDate}</p>
          </div>
        </div>
        <VerifiedBadge />
      </div>
      <div className="w-full p-4 flex flex-col items-center">
        <p className="w-full text-gray-700 overflow-x-hidden">
          {post.service_description && (
            <>
              {isExpanded
                ? post.service_description
                : post.service_description.slice(0, 180) + "..."}
              {post.service_description.length > 180 && (
                <button
                  className="text-blue-500 mt-2 self-start"
                  onClick={() => toggleDescription(post._id)}
                >
                  {isExpanded ? "See less" : "See more"}
                </button>
              )}
            </>
          )}
        </p>
        {post.video ? (
          <video
            src={post.video}
            alt="Post Media"
            className="w-full max-h-[400px] mt-4 rounded-lg"
            controls
            muted
          />
        ) : (
          <img
            src={dummyImgPost}
            alt="Post Media"
            className="w-1/3 max-h-[400px] mt-4 rounded-lg"
          />
        )}
      </div>
      <div className="w-full flex items-center justify-between px-6 py-4 border-t">
        <div className="text-gray-600 flex items-center">
          <span className="font-medium text-gray-600">{likes}</span>
          <ThumbUpIcon className="text-blue-500 ml-3" />
          {/* <BoltIcon className="text-yellow-500 -ml-1" /> */}
        </div>
        {post.average_rating && (
          <div className="text-gray-600 flex items-center">
            <span className="font-medium text-gray-600">
              {post.average_rating.toFixed(2)}
            </span>
            <BoltIcon className="text-yellow-500 -ml-1" />
          </div>
        )}
        <div className="text-gray-600">
          {post.comments?.length || 0} Comment
        </div>
      </div>
      {user && (
        <div className="w-full flex items-center justify-evenly py-3 border-t">
          <button
            onClick={() => handleLike(post._id)}
            className="flex items-center cursor-pointer border border-gray-300 px-4 py-2 rounded-full bg-white hover:bg-gray-100 interaction-button"
          >
            {liked ? (
              <ThumbUpIcon className="text-blue-500 mr-2" />
            ) : (
              <ThumbUpIcon className="text-gray-500 mr-2" />
            )}
            <p className="text-gray-600 hidden lg:block">Up</p>
          </button>
          <button
            onClick={handleRatingDialogOpen}
            className="flex items-center border border-gray-300 px-4 py-2 rounded-full bg-white hover:bg-gray-100 interaction-button"
          >
            <BoltIcon className="text-yellow-500 mr-2" />
            <p className="text-gray-600 hidden lg:block">Rating</p>
          </button>
          <button
            onClick={() => handleBoxVisibility("comment")}
            className="flex items-center border border-gray-300 px-4 py-2 rounded-full bg-white hover:bg-gray-100 interaction-button"
          >
            <CommentIcon className="text-green-500 mr-2" />
            <p className="text-gray-600 hidden lg:block">Comment</p>
          </button>
        </div>
      )}
      {visibleBox === "comment" && (
        <div
          ref={commentBoxRef}
          className="w-full p-4 border-t interaction-box"
        >
          <CommentSection postId={post._id} />
        </div>
      )}

      <Dialog open={isRatingDialogOpen} onClose={handleRatingDialogClose}>
        <DialogContent>
          <RatingBox
            postId={post._id}
            setIsRatingDialogOpen={setIsRatingDialogOpen}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PostItem;
