import React, { useContext, useEffect, useState } from "react";
import { BusinessContext } from "../../../context/businessContext";
import dummyImgPost from "../../../assets/noimage.jpg";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const FeaturedPost = () => {
  const { userBusiness } = useContext(BusinessContext);
  const [featuredPosts, setFeaturedPosts] = useState([]);
  // const [isSm, setIsSm] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});

  useEffect(() => {
    const handleResize = () => {
      // setIsSm(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const highestLikedPosts = userBusiness
      .filter((post) => post.like > 0 || post.comment > 5 || post.rating > 5)
      .slice(0, 2);
    setFeaturedPosts(highestLikedPosts);
  }, [userBusiness]);

  const toggleExpand = (id) => {
    setExpandedPosts((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  if (featuredPosts.length === 0) {
    return <div></div>;
  }

  return (
    <div className="w-full mt-7 p-4 bg-white rounded-md shadow-lg">
      <h2 className="text-2xl mb-4">Featured Post</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {featuredPosts.map((post) => {
          const isExpanded = expandedPosts[post._id];
          const descriptionClass = isExpanded ? "h-auto" : "line-clamp-2";
          return (
            <Card
              key={post._id}
              className="flex flex-col border border-gray-300 rounded-md shadow-md"
            >
              <CardContent className="flex-grow flex flex-col">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={`text-gray-700 ${descriptionClass}`}
                >
                  {post.service_description || post.name}
                </Typography>
                {post.service_description &&
                  post.service_description.length > 110 && (
                    <Button
                      onClick={() => toggleExpand(post._id)}
                      className="text-blue-500 mt-2 self-start"
                    >
                      {isExpanded ? "See less" : "See more"}
                    </Button>
                  )}
              </CardContent>
              <CardMedia
                component="img"
                image={post.logo ? post.logo : dummyImgPost}
                alt="Post Media"
                className="w-full h-48 object-cover rounded-b-md"
              />
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturedPost;
