import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/userContext";
import UpdateProfileBtn from "./UpdateProfileBtn";

const SkillInfo = ({ formData, handleChange, handleUpdate }) => {
  const { user } = useContext(UserContext);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleAddSkill = () => {
    handleChange("skills", "add", {
      skill: "",
      where_used_the_skill: "",
      certificate_name: "",
      certificate_organization: "",
      certificate_issue_date: "",
      certificate_expire_date: "",
      certificate_url: "",
    });
  };

  return (
    user && (
      <div className="w-full flex md:py-7 flex-col">
        <div className="w-full flex items-center mb-7 justify-end">
          {isMobileView && (
            <h1 className="text-xl mb-2 w-full font-semibold p-2">
              Skill & Certificates
            </h1>
          )}
        </div>

        {formData.skills.map((skillData, index) => (
          <div key={index} className="flex flex-col mb-4">
            <ul>
              <li className="md:list mb-2 font-medium">* Skill</li>
            </ul>
            <div className="flex flex-col  mb-4">
              <div className="w-1/2 md:pr-1 mb-7">
                <label className="block text-gray-500">Skill</label>
                <input
                  type="text"
                  placeholder="Enter Skill"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={skillData.skill}
                  onChange={(e) =>
                    handleChange("skills", index, "skill", e.target.value)
                  }
                />
              </div>
              <div className="w-1/2 md:pr-1 mb-7">
                <label className="block text-gray-500">
                  Where you used this skill
                </label>
                <input
                  type="text"
                  placeholder="Ex: University"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={skillData.where_used_the_skill}
                  onChange={(e) =>
                    handleChange(
                      "skills",
                      index,
                      "where_used_the_skill",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>

            <ul>
              <li className="mb-2 md:list font-medium">
                * License & Certification
              </li>
            </ul>

            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-gray-500">Certificate name</label>
                <input
                  type="text"
                  placeholder="Certificate name"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={skillData.certificate_name}
                  onChange={(e) =>
                    handleChange(
                      "skills",
                      index,
                      "certificate_name",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="w-1/2">
                <label className="block text-gray-500">
                  Issuing Organization
                </label>
                <input
                  type="text"
                  placeholder="Organization name"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={skillData.certificate_organization}
                  onChange={(e) =>
                    handleChange(
                      "skills",
                      index,
                      "certificate_organization",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-gray-500">Issue Date</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={skillData.certificate_issue_date}
                  onChange={(e) =>
                    handleChange(
                      "skills",
                      index,
                      "certificate_issue_date",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="w-1/2">
                <label className="block text-gray-500">Expiration Date</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={skillData.certificate_expire_date}
                  onChange={(e) =>
                    handleChange(
                      "skills",
                      index,
                      "certificate_expire_date",
                      e.target.value
                    )
                  }
                  min={skillData.certificate_issue_date}
                />
              </div>
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-full">
                <label className="block text-gray-500">Certificate URL</label>
                <input
                  type="text"
                  // placeholder="Certificate URL"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={skillData.certificate_url}
                  onChange={(e) =>
                    handleChange(
                      "skills",
                      index,
                      "certificate_url",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
        ))}

        <button
          className="w-1/2 max-w-[150px] md:px-4 py-2 border border-black rounded-lg cursor-pointer  mb-10"
          onClick={handleAddSkill}
        >
          Add New Skill
        </button>

        {!isMobileView && <UpdateProfileBtn handleUpdate={handleUpdate} />}
      </div>
    )
  );
};

export default SkillInfo;
