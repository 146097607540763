import React from "react";
import BusinessItem from "../../../components/widgits/BusinessItem";

const OldBusiness = () => {
  return (
    <div className="w-full flex flex-col items-center mt-9 mb-28">
      <BusinessItem />
    </div>
  );
};

export default OldBusiness;
