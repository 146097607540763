import React, { useContext } from "react";
import { UserContext } from "../../../context/userContext";
import { BusinessContext } from "../../../context/businessContext";
import PostItem from "../../../components/widgits/PostItem";

const MyPosts = () => {
  const { userBusiness } = useContext(BusinessContext);
  const { user } = useContext(UserContext);

  if (!userBusiness || !user) {
    return <div>No User Busineses</div>;
  }

  return (
    <div className="w-full flex flex-col items-center mt-9 mb-28 m-0 p-0">
      {userBusiness.map((post) => (
        <PostItem key={post._id} post={post} />
      ))}
    </div>
  );
};

export default MyPosts;
