import React from "react";

const CompanyDetails = ({ formData, handleChange }) => {
  return (
    <form className="w-full mx-auto flex flex-col justify-between rounded-xl md:mb-10">
      <div className="flex flex-col md:mt-6 mb-4 w-full">
        <label htmlFor="service_description" className="text-lg mb-2">
          Product or Service Description
        </label>
        <textarea
          id="service_description"
          name="service_description"
          value={formData.service_description}
          onChange={(e) => handleChange("service_description", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
          rows="4"
        ></textarea>
      </div>
      <div className="mb-4 flex flex-col">
        <label htmlFor="slogan" className="text-lg mb-2">
          Slogan
        </label>
        <input
          id="slogan"
          name="slogan"
          placeholder="slogan"
          type="text"
          value={formData.slogan}
          onChange={(e) => handleChange("slogan", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4 flex flex-col">
        <label htmlFor="company_vision" className="text-lg mb-2">
          Company Vision
        </label>
        <input
          id="company_vision"
          name="company_vision"
          placeholder="Company Vision"
          type="text"
          value={formData.company_vision}
          onChange={(e) => handleChange("company_vision", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4 flex flex-col">
        <label htmlFor="company_mission" className="text-lg mb-2">
          Company Mission
        </label>
        <input
          id="company_mission"
          name="company_mission"
          placeholder="Company Mission"
          type="text"
          value={formData.company_mission}
          onChange={(e) => handleChange("company_mission", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4 flex flex-col">
        <label htmlFor="company_created_date" className="text-lg mb-2">
          Date created
        </label>
        <input
          id="company_created_date"
          name="company_created_date"
          type="date"
          value={formData.company_created_date}
          onChange={(e) => handleChange("company_created_date", e.target.value)}
          className="md:w-2/3 p-2 border border-gray-300 rounded-md"
        />
      </div>
    </form>
  );
};

export default CompanyDetails;
