import React, { useContext, useState } from "react";
import {
  useMediaQuery,
  useTheme,
  Button,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  ClickAwayListener,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle,
  Home,
  Notifications as NotificationsIcon,
  ArrowDropDown,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { deleteUser } from "../../utils/apiFunctions";
import { useSnackbar } from "notistack";
import MobileMenu from "./MobileMenu";
import logo from "../../assets/logo.png";
import BusinessSvg from "../../assets/svg/businessSvg";

export default function Header() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  // Menu handlers
  const handleMenuToggle = (event) => setAnchorEl(event.currentTarget);
  const handleMobileMenuToggle = (event) =>
    setMobileMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleMobileMenuClose = () => setMobileMenuAnchorEl(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload()
    navigate("/signup");
  };

  const handleDelete = async () => {
    const userId = user._id;
    const response = await deleteUser(userId);
    if (response) {
      localStorage.removeItem("token");
      navigate("/signup");
      enqueueSnackbar("Account Deleted", { variant: "success" });
    } else {
      enqueueSnackbar("Failed to delete account", { variant: "error" });
    }
  };

  const handleIconClick = (path) => navigate(path);

  const iconStyle = (path) => ({
    color: location.pathname === path ? theme.palette.primary.main : "black",
    fontSize: isXs ? "20px" : "24px",
    marginRight: isSm ? "8px" : "16px",
  });

  const noHoverEffect = {
    "&:hover": {
      backgroundColor: "transparent",
    },
    paddingX: 0,
    marginLeft: 2,
  };
  const noHoverEffectProfile = {
    "&:hover": {
      backgroundColor: "transparent",
    },
    paddingX: 0,
    marginLeft: -1,
  };

  const accountBoxStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid lightblue",
    borderRadius: "20px",
    cursor: "pointer",
    height: 40,
    paddingX: 2,
    marginLeft: 5,
  };

  if (!user) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: "#fff", mb: 2 }}>
          <Toolbar>
            <img
              src={logo}
              alt="logo"
              style={{ cursor: "pointer", width: isXs ? "90px" : "120px" }}
              onClick={() => navigate("/")}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                onClick={() => navigate("/signup")}
                sx={{
                  color: "#000",
                  textTransform: "none",
                  borderRadius: 3,
                  fontWeight: "bold",
                  border: "1px solid",
                }}
              >
                Log In
              </Button>
              <Button
                onClick={() => navigate("/signup")}
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  textTransform: "none",
                  borderRadius: 3,
                  fontWeight: "bold",
                  border: "1px solid",
                }}
              >
                Sign Up
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }

  const profileImageUrl = user.profile_picture || null;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#fff", mb: 2 }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <img
            src={logo}
            alt="logo"
            style={{ cursor: "pointer", width: isXs ? "90px" : "120px" }}
            onClick={() => navigate("/")}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {/* Home */}
            <IconButton
              aria-label="home"
              onClick={() => handleIconClick("/")}
              sx={noHoverEffect}
              disableRipple
              disableFocusRipple
            >
              <Home sx={iconStyle("/")} />
              {!isSm && location.pathname === "/" && (
                <span className="text-black text-base">Home</span>
              )}
            </IconButton>

            {/* My Business */}
            <IconButton
              aria-label="My Business"
              onClick={() => handleIconClick("/business")}
              sx={noHoverEffect}
              disableRipple
              disableFocusRipple
            >
              <BusinessSvg
                color={location.pathname === "/business" ? "blue" : "black"}
              />
              {!isSm && location.pathname === "/business" && (
                <span className="text-black ml-4 text-base">My Business</span>
              )}
            </IconButton>

            {/* Notifications */}
            <IconButton
              aria-label="notifications"
              onClick={() => handleIconClick("/notification")}
              sx={noHoverEffect}
              disableRipple
              disableFocusRipple
            >
              <NotificationsIcon sx={iconStyle("/notification")} />
            </IconButton>

            {/* Account Menu */}
            <ClickAwayListener onClickAway={handleMenuClose}>
              <Box sx={isSm ? {} : accountBoxStyle} >
                <IconButton
                  aria-label="account"
                  onClick={handleMenuToggle}
                  sx={noHoverEffectProfile}
                  disableRipple
                  disableFocusRipple
                >
                  {profileImageUrl ? (
                    <img
                      src={profileImageUrl}
                      alt={user.first_name}
                      style={{ width: "32px", height: "32px", borderRadius: "50%" }}
                    />
                  ) : (
                    <AccountCircle sx={iconStyle("/profile")} />
                  )}
                </IconButton>
                {!isSm && (
                  <>
                    <p
                      className="text-black text-sm font-bold ml-2"
                      onClick={handleMenuToggle}
                    >
                      {user.first_name}
                    </p>
                    <ArrowDropDown
                      onClick={handleMenuToggle}
                      sx={{ fontSize: "30px", color: "black" }}
                    />
                  </>
                )}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={() => navigate("/profile")}>
                    Go to My Profile
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  <MenuItem onClick={handleDelete}>Delete Account</MenuItem>
                </Menu>
              </Box>
            </ClickAwayListener>

            {/* Mobile Menu */}
            {isMd && (
              <ClickAwayListener onClickAway={handleMobileMenuClose}>
                <div>
                  <IconButton
                    aria-label="menu"
                    onClick={handleMobileMenuToggle}
                    sx={noHoverEffect}
                  >
                    <MenuIcon sx={iconStyle("/compact")} />
                  </IconButton>
                  <Menu
                    anchorEl={mobileMenuAnchorEl}
                    open={Boolean(mobileMenuAnchorEl)}
                    onClose={handleMobileMenuClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MobileMenu
                      handleMobileMenuClose={handleMobileMenuClose}
                      user={user}
                    />
                  </Menu>
                </div>
              </ClickAwayListener>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
