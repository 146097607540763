import React, { createContext, useState, useEffect } from "react";
import { getBusinesses } from "../utils/apiFunctions";

export const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const [businesses, setBusinesses] = useState([]);
  const [userBusiness, setUserBusiness] = useState([]);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserId(token);
    }
  }, []);

  //   get all businesses
  useEffect(() => {
    const fetchBusinesses = async () => {
      const response = await getBusinesses();
      setBusinesses(response);
    };
    fetchBusinesses();
  }, []);
  // console.log(businesses);

  // get users businesses
  useEffect(() => {
    if (userId && businesses.length > 0) {
      const userBusinesses = businesses.filter(
        (business) => business.user_id === userId
      );
      setUserBusiness(userBusinesses);
    }
  }, [userId, businesses]);
  // console.log(userBusiness);

  return (
    <BusinessContext.Provider
      value={{
        businesses,
        userBusiness,
        setUserBusiness,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};
