import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { UserContext } from "../../../context/userContext";
import dummyImage from "../../../assets/dummyProfile.jpg";

const UserCard = ({ user, baseUrl }) => {
  const profileImageUrl = user.profile_picture
    ? `${user.profile_picture}`
    : dummyImage;

  // Split names and get the first word from each
  const firstName = user.first_name
 
  return (
    <Box
      key={user._id}
      className="flex flex-col items-center justify-center"
      sx={{ flex: `0 0 14%` }}
    >
      <img
        src={profileImageUrl}
        alt={`Profile of ${user.first_name}`}
        className="rounded-full w-16 h-16 border-blue-700 border-2"
      />
      <Box
        className="text-sm w-full overflow-x-auto"
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        <p className="w-full text-center">
          {firstName}
        </p>
      </Box>
    </Box>
  );
};

const UserBar = () => {
  const { allUsers } = useContext(UserContext);
  const containerRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Show loading if data is not ready
  if (!allUsers || !Array.isArray(allUsers)) {
    return <div>Loading...</div>;
  }

  
  const scrollAmount = 200;

  const handlePrevClick = () => {
    containerRef.current.scrollBy({
      left: -scrollAmount,
      behavior: "smooth",
    });
  };

  const handleNextClick = () => {
    containerRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: "100%",
        marginBottom: 0,
        overflow: "hidden",
      }}
    >
      {!isMobileView && (
        <IconButton
          onClick={handlePrevClick}
          sx={{
            position: "absolute",
            left: 0,
            zIndex: 1,
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              backgroundColor: "#F0F0F0",
            },
          }}
        >
          <ArrowBack sx={{ color: "#1976d2", fontSize: 24 }} />
        </IconButton>
      )}
      <Box
        ref={containerRef}
        className="flex flex-nowrap space-x-4 h-full"
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          paddingX: 1,
        }}
      >
        {allUsers.map((user) => (
          <UserCard key={user._id} user={user} />
        ))}
      </Box>
      {!isMobileView && (
        <IconButton
          onClick={handleNextClick}
          sx={{
            position: "absolute",
            right: 0,
            zIndex: 1,
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              backgroundColor: "#F0F0F0",
            },
          }}
        >
          <ArrowForward sx={{ color: "#1976d2", fontSize: 24 }} />
        </IconButton>
      )}
    </Box>
  );
};

export default UserBar;
