import {  useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import bgImg from "../../assets/Frame 11.png";
import vectorImg from "../../assets/Vector.png";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { createUser, getUserByEmail } from "../../utils/apiFunctions";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const LoginSignup = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleUserLogin = async (user) => {
    localStorage.setItem("token", user._id);
    navigate("/");
    window.location.reload();
    enqueueSnackbar("Welcome Again", { variant: "success" });
  };

  const signInWithGoogle = async (response) => {
    setLoading(true);
    const decoded = jwtDecode(response.credential);
    const email = decoded.email;
    try {
      let userResponse = await getUserByEmail(email);
      await handleUserLogin(userResponse);
    } catch (error) {
      const newUser = {
        first_name: decoded.given_name,
        last_name: decoded.family_name,
        email: decoded.email,
        profile_picture: decoded.picture,
        mobile: null,
        nic: null,
        // business_category: null,
      };

      const userResponse = await createUser(newUser);
      if (!userResponse) {
        enqueueSnackbar("Failed to create user", { variant: "error" });
      } else {
        // await handleUserLogin(userResponse.user_id);
        localStorage.setItem("token", userResponse.user_id);
        navigate("/");
        window.location.reload();
        enqueueSnackbar("Welcome", { variant: "success" });
      }
    } finally {
      setLoading(false);
    }
  };

  const signInWithFacebook = async (response) => {
    setLoading(true);
    const { email, first_name, last_name, picture } = response.data;
    try {
      const userResponse = await getUserByEmail(email);

      await handleUserLogin(userResponse);
    } catch (error) {
      const newUser = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        profile_picture: picture.data.url,
        mobile: null,
        nic: null,
        // business_category: null,
      };

      const userResponse = await createUser(newUser);
      if (!userResponse) {
        enqueueSnackbar("Failed to create user", { variant: "error" });
      } else {
        localStorage.setItem("token", userResponse.user_id);
        navigate("/");
        window.location.reload();
        enqueueSnackbar("Welcome", { variant: "success" });
      }
    } finally {
      setLoading(false);
    }
  };

  const facebookAppID = process.env.REACT_APP_FACEBOOK_APP_ID;

  return (
    // <Fragment>
    //   <div className="min-h-screen flex items-center justify-center bg-gray-50">
    <div className="w-full min-h-screen max-w-5xl mx-auto my-auto  flex flex-col md:flex-row bg-white shadow-md rounded-lg overflow-hidden">
      <div className="md:w-1/2 hidden md:block">
        <img src={bgImg} alt="bgImg" className="w-full h-full object-cover" />
      </div>
      <div className="w-full md:h-[600px] justify-evenly md:w-1/2 flex flex-col items-center p-6 md:p-10">
        <h2 className="text-2xl font-bold mb-4">Sign Up to ALL.lk</h2>
        {loading ? (
          <div className="mt-4">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
              <span className="visually-hidden"></span>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center">
            <GoogleLogin
              onSuccess={signInWithGoogle}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            <div className="flex items-center justify-center my-4">
              <img src={vectorImg} alt="------" />
              <span className="mx-2 text-gray-500">Or</span>
              <img src={vectorImg} alt="------" />
            </div>
            <LoginSocialFacebook
              appId={facebookAppID}
              onResolve={signInWithFacebook}
              onReject={() => console.log("Facebook rejected")}
            >
              <FacebookLoginButton />
            </LoginSocialFacebook>
          </div>
        )}
        <div className="w-full mt-6 text-center">
          <p className="text-gray-500 text-sm">
            By creating an account you agree with our Terms of Service, privacy
            policy, and our default notification settings.
          </p>
          <p className="text-gray-500 text-sm mt-4">
            Already have an account?{" "}
            <a href="/signup" className="text-blue-500">
              Sign In
            </a>
          </p>
        </div>
      </div>
    </div>
    //   </div>
    // </Fragment>
  );
};

export default LoginSignup;
