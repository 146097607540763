import React, { useState, useEffect, useContext } from "react";
import { getComments } from "../../utils/apiFunctions";
import CommentBox from "./CommentBox";
import { Box, Button, Typography, Paper, Avatar } from "@mui/material";
import { UserContext } from "../../context/userContext";

const CommentSection = ({ postId }) => {
  const [comments, setComments] = useState([]);
  const [replyTo, setReplyTo] = useState(null);
  const [visibleComments, setVisibleComments] = useState(4);
  const { allUsers } = useContext(UserContext);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await getComments(postId);
        setComments(response.comments);
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchComments();
  }, [postId]);

  const handleCommentAdded = () => {
    const fetchComments = async () => {
      try {
        const response = await getComments(postId);
        setComments(response.comments);
        setReplyTo(null); // Hide reply input after comment is added
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchComments();
  };

  const getUserName = (userId) => {
    const user = allUsers.find((user) => user._id === userId);
    return user ? `${user.first_name} ${user.last_name}` : "Unknown User";
  };

  const getUserProfilePicture = (userId) => {
    const user = allUsers.find((user) => user._id === userId);
    return user ? user.profile_picture : "";
  };

  const renderReplies = (replies) => {
    return replies.map((reply) => (
      <Box
        key={reply._id}
        sx={{ ml: 6, mt: 1, display: "flex", alignItems: "flex-start" }}
      >
        <Avatar
          src={getUserProfilePicture(reply.user_id)}
          sx={{ width: 24, height: 24, mr: 1 }}
          alt={getUserName(reply.user_id)}
        />
        <Paper
          elevation={1}
          sx={{
            p: 1,
            flexGrow: 1,
            backgroundColor: "#f1f1f1",
            borderRadius: "8px",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {getUserName(reply.user_id)} |{" "}
            <span style={{ fontSize: "0.8rem", color: "#888" }}>
              {new Date(reply.date).toLocaleString()}
            </span>
          </Typography>
          <Typography variant="body2">{reply.text}</Typography>
        </Paper>
      </Box>
    ));
  };

  const renderComments = () => {
    return comments.slice(0, visibleComments).map((comment) => (
      <Box key={comment._id} sx={{ mt: 2 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Avatar
            src={getUserProfilePicture(comment.user_id)}
            sx={{ width: 32, height: 32, mr: 2 }}
            alt={getUserName(comment.user_id)}
          />
          <Paper elevation={2} sx={{ p: 2, flexGrow: 1, borderRadius: "12px" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {getUserName(comment.user_id)} |{" "}
              <span style={{ fontSize: "0.8rem", color: "#888" }}>
                {new Date(comment.date).toLocaleString()}
              </span>
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {comment.text}
            </Typography>
            {comment.media && (
              <Box sx={{ mt: 2, textAlign: "center" }}>
                {comment.media.endsWith(".mp4") ||
                comment.media.endsWith(".webm") ? (
                  <video
                    controls
                    style={{ maxHeight: "100px", borderRadius: "10px" }}
                  >
                    <source src={comment.media} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={comment.media}
                    alt={comment.media}
                    style={{ maxHeight: "100px", borderRadius: "10px" }}
                  />
                )}
              </Box>
            )}
            <Button
              size="small"
              sx={{ mt: 1, color: "#3b5998" }}
              onClick={() =>
                setReplyTo(replyTo === comment._id ? null : comment._id)
              }
            >
              {replyTo === comment._id ? "Cancel" : "Reply"}
            </Button>
            {comment.replies.length > 0 && (
              <Button
                size="small"
                sx={{ mt: 1, color: "#3b5998" }}
                onClick={() =>
                  setReplyTo(replyTo === comment._id ? null : comment._id)
                }
              >
                {replyTo === comment._id
                  ? "Hide Replies"
                  : `View Replies (${comment.replies.length})`}
              </Button>
            )}
          </Paper>
        </Box>
        {replyTo === comment._id && renderReplies(comment.replies)}
        {replyTo === comment._id && (
          <Box sx={{ ml: 8, mt: 2 }}>
            <CommentBox
              postId={postId}
              parentCommentId={comment._id}
              onCommentAdded={handleCommentAdded}
            />
          </Box>
        )}
      </Box>
    ));
  };

  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Typography variant="h6" gutterBottom>
        Comments
      </Typography>
      {renderComments()}
      {comments.length > visibleComments && (
        <Button
          sx={{ mt: 2, color: "#3b5998" }}
          onClick={() => setVisibleComments(visibleComments + 3)}
        >
          Load More Comments
        </Button>
      )}
      <CommentBox postId={postId} onCommentAdded={handleCommentAdded} />
    </Box>
  );
};

export default CommentSection;
