import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../../context/userContext";
import profileImg from "../../../../assets/dummyProfile.jpg";
import UpdateProfileBtn from "./UpdateProfileBtn";
import axios from "axios";

const PersonalInfo = ({ formData, handleChange, handleUpdate }) => {
  const { user } = useContext(UserContext);
  const [isMobileView, setIsMobileView] = useState(false);
  const [profileImage, setProfileImage] = useState(
    formData.profile_picture ? `${formData.profile_picture}` : profileImg
  );
  // const [errors, setErrors] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Image upload
  const uploadPreset = process.env.REACT_APP_PROFILE_PICTURE_UPLOAD_PRESET;
  const cloudName = process.env.REACT_APP_CLOUD_NAME;
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formDataImage = new FormData();
    formDataImage.append("file", file);
    formDataImage.append("upload_preset", uploadPreset);
    formDataImage.append("folder", "profile_pictures");

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formDataImage
      );
      setProfileImage(res.data.secure_url);
      handleChange("profile_picture", res.data.secure_url);
    } catch (error) {
      console.error("Error updating image: ", error);
    }
  };

  // const validateFields = () => {
  //   const newErrors = {};
  //   // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   // if (!emailPattern.test(formData.email)) {
  //   //   newErrors.email = "Invalid email format";
  //   // }
  //   if (formData.mobile && formData.mobile.length !== 10) {
  //     setErrors("Mobile number must be 10 digits");
  //   }
  //   return Object.keys(newErrors).length === 0;
  // };

  // const handleSubmit = () => {
  //   if (validateFields()) {
  //     handleUpdate();
  //   }
  // };

  if (!user) {
    return <div></div>;
  }

  return (
    user && (
      <div className="w-full flex flex-col">
        {isMobileView && (
          <h1 className="text-xl mb-2 w-full font-semibold p-2">
            Personal Information
          </h1>
        )}
        <div className="relative mb-6 rounded-full mx-auto md:mx-0 w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32">
          <img
            src={profileImage}
            alt="Profile"
            className="rounded-full w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 border-2 border-black"
          />
          <span className="absolute bottom-0 right-0 bg-blue-500 rounded-full p-2 cursor-pointer border-4 border-white">
            ✏️
          </span>
          <input
            type="file"
            accept="image/*"
            className="absolute inset-0 opacity-0 cursor-pointer"
            onChange={handleImageUpload}
          />
        </div>
        <div className="w-full max-w-3xl">
          <div className="flex space-x-4 mb-4">
            <div className="w-1/2">
              <label className="block text-gray-500">First Name</label>
              <input
                type="text"
                placeholder="Enter your first name"
                className="w-full border border-gray-300 p-2 rounded"
                value={formData.first_name}
                onChange={(e) => handleChange("first_name", e.target.value)}
              />
            </div>
            <div className="w-1/2">
              <label className="block text-gray-500">Last Name</label>
              <input
                type="text"
                placeholder="Enter your last name"
                className="w-full border border-gray-300 p-2 rounded"
                value={formData.last_name}
                onChange={(e) => handleChange("last_name", e.target.value)}
              />
            </div>
          </div>
          <div className="flex space-x-4 mb-4">
            <div className="w-1/2">
              <label className="block text-gray-500">Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full border border-gray-300 p-2 rounded"
                value={formData.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
              {/* {errors.email && <p className="text-red-500">{errors.email}</p>} */}
            </div>
            <div className="md:w-1/2 w-full">
              <label className="block text-gray-500">Address</label>
              <input
                type="text"
                placeholder="Enter your address"
                className="w-full border border-gray-300 p-2 rounded"
                value={formData.address}
                onChange={(e) => handleChange("address", e.target.value)}
              />
            </div>
          </div>
          <div className="flex md:space-x-4 mb-4 md:flex-row flex-col">
            <div className="w-1/2">
              <label className="block text-gray-500">Contact</label>
              <input
                type="number"
                placeholder="Enter your mobile number"
                className="w-full border border-gray-300 p-2 rounded"
                minLength={10}
                maxLength={10}
                value={formData.mobile}
                onChange={(e) => handleChange("mobile", e.target.value)}
              />
              {/* {errors.mobile && <p className="text-red-500">{errors.mobile}</p>} */}
            </div>

            <div className="md:w-1/2 w-full">
              <label className="block text-gray-500">ID Number</label>
              <input
                type="text"
                placeholder="Enter your ID"
                className="w-full border border-gray-300 p-2 rounded"
                value={formData.nic}
                maxLength={12}
                minLength={10}
                onChange={(e) => handleChange("nic", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-4 md:w-1/2 md:pr-2">
            <label className="block text-gray-500">Date of Birth</label>
            <input
              type="date"
              className="w-full border border-gray-300 p-2 rounded"
              value={formData.dob}
              onChange={(e) => handleChange("dob", e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-500">About / Bio</label>
            <textarea
              placeholder="Add Description about you"
              className="w-full border border-gray-300 p-2 rounded h-24"
              value={formData.about}
              onChange={(e) => handleChange("about", e.target.value)}
            ></textarea>
          </div>
          {!isMobileView && <UpdateProfileBtn handleUpdate={handleUpdate} />}
        </div>
      </div>
    )
  );
};

export default PersonalInfo;
