import React from "react";
import { Box, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const VerifiedBadge = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      <CircleIcon style={{ color: "#1DA1F2", fontSize: 9 }} />
      <Typography variant="body2" style={{ color: "#1DA1F2", marginLeft: 4,fontSize:12 }}>
        Verified
      </Typography>
    </Box>
  );
};

export default VerifiedBadge;
