import React, { useContext } from "react";
import { UserContext } from "../../../context/userContext";
import { Box } from "@mui/material";
import ProfileCompletion from "./ProfileCompleteBar";
import ProfileHeader from "./Profileheader";
import MyPosts from "./MyPosts";
import FeaturedPost from "./FeaturedPost";

const MyProfile = () => {
  const { user } = useContext(UserContext);
  // console.log(user);

  if (!user) {
    return (
      <Box
        className="hidden md:block w-1/4 p-4"
        display="flex"
        flexDirection="column"
        alignItems="center"
      ></Box>
    );
  }

  return (
    user && (
      <div className="w-full flex flex-col ">
        <h1 className="text-3xl font-semibold">My Profile</h1>
        <div className="w-full flex  md:flex-col flex-col-reverse">
          <ProfileCompletion />
          <ProfileHeader />
        </div>
        <FeaturedPost />
        <MyPosts />
      </div>
    )
  );
};

export default MyProfile;
