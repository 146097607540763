import React, { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
// import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PercentIcon from "@mui/icons-material/Percent";
import { useNavigate } from "react-router-dom";
import { addPromotion } from "../../../utils/apiFunctions";
import axios from "axios";
import { useSnackbar } from "notistack";

// Custom Switch styles
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 30,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 28,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(20px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 22,
    height: 22,
    borderRadius: 11,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },

  // Responsive styles for mobile
  [theme.breakpoints.down("sm")]: {
    width: 40,
    height: 21,
    "& .MuiSwitch-thumb": {
      width: 16,
      height: 14,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(16px)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const discountTypes = ["Percentage", 'lorem ipsum'];
const discountLimits = [0, 10, 30, 50, 80, 100];

function getStyles(name, selected, theme) {
  return {
    fontWeight:
      selected === name
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  };
}

const PromotionScreen = () => {
  const [noEndDate, setNoEndDate] = useState(false);
  const [description, setDescription] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountLimit, setDiscountLimit] = useState("");
  const [validTill, setValidTill] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [promotionName, setPromotionName] = useState("");
  const [promotionUrl, setPromotionUrl] = useState("")
  const [discountValue, setDiscountValue] = useState();
  const [mediaType, setMediaType] = useState("");
  const [imgLink, setImgLink] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();

  const cloudName = process.env.REACT_APP_CLOUD_NAME;
  const uploadVideoPreset = process.env.REACT_APP_BUSINESS_VIDEO_UPLOAD_PRESET;
  const uploadImagesPreset = process.env.REACT_APP_BUSINESS_IMAGE_UPLOAD_PRESET;

  // Disable Save button until upload is completed and check if all required fields are filled
  const isSaveDisabled = uploadProgress < 100 || !promotionName || !discountValue || !discountType || !imgLink;

  // Get today's date in YYYY-MM-DD format for the minimum date in the calendar
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
  };

  // Handle File Upload
  const handleFileUpload = async (file) => {
    const formData = new FormData();
    const isVideo = file.type.startsWith("video");

    const uploadPreset = isVideo ? uploadVideoPreset : uploadImagesPreset;
    const folder = isVideo ? "business_videos" : "business_images";

    if (isVideo) {
      setMediaType("video");
    } else {
      setMediaType("image");
    }

    formData.append("file", file);
    formData.append("upload_preset", uploadPreset);
    formData.append("folder", folder);

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/${isVideo ? "video" : "image"
        }/upload`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );

      enqueueSnackbar(
        `File uploaded successfully as ${isVideo ? "video" : "image"}`,
        { variant: "success" }
      );
      setImgLink(res.data.secure_url); // Save the uploaded image/video link
      return res.data.secure_url;
    } catch (error) {
      console.error("Error uploading file:", error);
      enqueueSnackbar("File upload failed.", { variant: "error" });
      return null;
    }
  };

  // Save Promotion
  const handleSavePromotion = async (e) => {
    e.preventDefault();

    // Ensure the validTill date has time component or send null if noEndDate is checked
    let formattedEndDate = null;
    if (!noEndDate && validTill) {
      formattedEndDate = `${validTill} 00:00:00`;
    }

    const promotionData = {
      userId: localStorage.getItem("token"),
      promotionName: promotionName,
      promotionUrl: promotionUrl,
      discountValue: Number(discountValue),
      discountLimit,
      discountType,
      description,
      media: [imgLink],
      media_type: mediaType,
      endDate: formattedEndDate,
    };

    try {
      await addPromotion(promotionData);
      enqueueSnackbar("Promotion saved successfully!", { variant: "success" });
      navigate("/profile");
    } catch (error) {
      enqueueSnackbar("Failed to save promotion.", { variant: "error" });
    }
  };

  // Dropzone component
  const DropzoneComponent = ({ onDrop }) => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
          const selectedFile = acceptedFiles[0];
          setFile(selectedFile);

          // Automatically trigger file upload after file is selected
          handleFileUpload(selectedFile);
        }
      },
      accept: "image/*,video/*",
      maxFiles: 1,
    });

    const dropzoneBorderColor =
      uploadProgress === 100
        ? "green"
        : `rgba(0, 128, 0, ${uploadProgress / 100})`;

    return (
      <div
        {...getRootProps()}
        className="w-full"
        style={{
          border: `2px dashed ${dropzoneBorderColor}`,
          padding: "20px",
          borderRadius: "12px",
        }}
      >
        <input {...getInputProps()} />
        <p className="text-center text-[#9A9D9F] text-base md:text-2xl text-opacity-55">
          Select or Drag and drop your image or video
        </p>
        {file && (
          <p className="text-center mt-3">
            Selected File: {file.name} ({uploadProgress}% uploaded)
          </p>
        )}
      </div>
    );
  };

  // Handle description change
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 200) {
      setDescription(value);
    }
  };

  return (
    <div className="w-full flex flex-col pl-0 md:pl-2 mb-10 sm:bg-transparent">
      <h1 className="text-3xl font-semibold mt-0  md:mt-3 md:mb-8 ">Promotions</h1>
      <div className="w-full flex flex-col justify-between">
        <Box className="w-full flex flex-col justify-between bg-white p-2 px-5 rounded-xl shadow-2xl mb-16">
          <h1 className="text-[#1E3D58] text-xl font-semibold my-3 md:my-6 ">
            Promotion details
          </h1>

          <Box className="w-full mb-5">
            <h1 className="text-[#1E3D58] font-medium mb-1">Promotion name</h1>
            <input
              type="text"
              className=" w-full md:w-1/2  border border-gray-300 rounded p-3"
              value={promotionName}
              onChange={(e) => setPromotionName(e.target.value)}
              required
            />
          </Box>
          <Box className="w-full mb-5">
            <h1 className="text-[#1E3D58] font-medium mb-1">Promotion URL</h1>
            <input
              type="text"
              className=" w-full md:w-1/2  border border-gray-300 rounded p-3"
              value={promotionUrl}
              onChange={(e) => setPromotionUrl(e.target.value)}
              required
            />
          </Box>

          <Box className="w-full md:w-1/2 mb-5">
            <h1 className="text-[#1E3D58] font-medium mb-1">Valid till</h1>
            <Box className="w-full flex items-center gap-5 md:gap-10 justify-between">
              <input
                type="date"
                className={`w-full md:w-2/3  border border-gray-300 rounded p-3 ${noEndDate ? "cursor-not-allowed opacity-50" : ""
                  }`}
                value={validTill}
                min={getTodayDate()} // Prevent past dates from being selected
                disabled={noEndDate}
                onChange={(e) => setValidTill(e.target.value)}
                style={{
                  backgroundColor: noEndDate ? "#f0f0f0" : "#fff",
                  color: noEndDate ? "#b0b0b0" : "#000",
                }}
              />
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <AntSwitch
                  checked={noEndDate}
                  onChange={(e) => setNoEndDate(e.target.checked)}
                  inputProps={{ "aria-label": "no end date switch" }}
                />
                <label className="text-sm md:text-base" sx={{ color: "#1E3D58" }}>No end</label>
              </Stack>
            </Box>
          </Box>

          <Box className="w-full mb-5">
            <h1 className="text-[#1E3D58] font-medium md:text-base text-xs mb-1">
              Image or video
              <span className="text-red-600 font-normal md:text-sm text-xs">
                (You can add one image or one video)
              </span>
            </h1>
            <div className="flex flex-col min-h-20 w-full mb-6 items-center justify-center cursor-pointer border  md:rounded-2xl">
              <DropzoneComponent />
            </div>
          </Box>

          <Box className="w-full mb-5">
            <Box className="w-full flex justify-between pr-10">
              <h1 className="text-[#1E3D58] font-medium mb-1">Description</h1>
              <h1
                style={{
                  color: description.length === 200 ? "red" : "#1E3D58",
                }}
                className="text-[#1E3D58] font-medium mb-1"
              >
                {description.length}/200
              </h1>
            </Box>
            <textarea
              className="w-full p-4 border border-gray-300 rounded"
              cols="50"
              rows="2"
              maxLength={200}
              value={description}
              onChange={handleDescriptionChange}
            />
          </Box>

          <Box className="w-full mb-5 gap-2 md:gap-10 flex-wrap sm:flex   items-start py-2 ">
            <div className="flex w-full sm:w-1/2 justify-between items-center ">
              <Box className="w-1/2 sm:w-2/3 " >
                <h1 className="text-[#1E3D58] font-medium ">Create</h1>
                <FormControl sx={{ m: 0, width: '100%' }}>
                  <Select
                    value={discountType}
                    onChange={(e) => setDiscountType(e.target.value)}
                    displayEmpty
                    MenuProps={MenuProps}
                  >
                    {discountTypes.map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                        style={getStyles(type, discountType, theme)}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box className="h-full  items-end ">
                <h1 className="text-[#1E3D58] font-medium">Of</h1>
                <Box className="flex justify-start bg-[#D9D9D9] items-center rounded-md border-black h-full w-28">
                  <input
                    className="border outline-none rounded-l-md border-[#D9D9D9] w-16 text-lg text-center p-2 py-3"
                    type="number"
                    style={{
                      appearance: "textfield",
                      "-moz-appearance": "textfield",
                      "-webkit-appearance": "none",
                    }}
                    value={discountValue}
                    onChange={(e) => setDiscountValue(e.target.value)}
                  />
                  <div className="flex justify-center w-full bg-[#D9D9D9] items-center h-full">
                    <PercentIcon />
                  </div>
                </Box>
              </Box>
            </div>

            <Box className="  w-full sm:w-1/4 " >
              <h1 className="text-[#1E3D58] font-medium ">Limit</h1>
              <FormControl sx={{ m: 0, width: '100%' }}>
                <Select
                  value={discountLimit}
                  onChange={(e) => setDiscountLimit(e.target.value)}
                  displayEmpty
                  MenuProps={MenuProps}
                >
                  {discountLimits.map((limit) => (
                    <MenuItem
                      key={limit}
                      value={limit}
                      style={getStyles(limit, discountLimit, theme)}
                    >
                      {limit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <div className="flex my-10 ">
            <button
              onClick={() => navigate("/profile")}
              className="bg-white w-1/2 md:w-1/4 border-gray-300 border text-black px-10 py-2 rounded"
            >
              Cancel
            </button>
            <button
              disabled={isSaveDisabled} // Disable save button until upload completes
              onClick={handleSavePromotion}
              className={`w-1/2 md:w-1/4 text-white px-10 py-2 rounded ml-5 ${isSaveDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500"
                }`}
            >
              Save Promotion
            </button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default PromotionScreen;
