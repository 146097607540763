import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/userContext";
import UpdateProfileBtn from "./UpdateProfileBtn";

const EducationInfo = ({ formData, handleChange, handleUpdate }) => {
  const { user } = useContext(UserContext);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleAddEducation = () => {
    handleChange("education", "add", {
      school: "",
      degree: "",
      field_of_study: "",
      grade: "",
      school_start_date: "",
      school_end_date: "",
    });
  };

  return (
    user && (
      <div className="w-full flex md:py-7 flex-col">
        <div className="w-full flex items-center mb-4 justify-end">
          {isMobileView && (
            <h1 className="text-xl mb-2 w-full font-semibold p-2">
              Education Information
            </h1>
          )}
        </div>

        {formData.education.map((education, index) => (
          <div className="flex flex-col mb-4" key={index}>
            <div className="flex space-x-4 md:space-x-0 mb-4 md:flex-col">
              <div className="w-1/2 pr-1 mb-4 md:mb-10">
                <label className="block text-gray-500">School Name</label>
                <input
                  type="text"
                  placeholder="Ex: Boston University"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={education.school}
                  onChange={(e) =>
                    handleChange("education", index, "school", e.target.value)
                  }
                />
              </div>
              <div className="w-1/2 pr-1 mb-4 md:mb-10">
                <label className="block text-gray-500">Degree</label>
                <input
                  type="text"
                  placeholder="Ex: Bachelor's"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={education.degree}
                  onChange={(e) =>
                    handleChange("education", index, "degree", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex space-x-4 md:space-x-0 mb-4 md:flex-col">
              <div className="w-1/2 pr-1 mb-4 md:mb-10">
                <label className="block text-gray-500">Field of Study</label>
                <input
                  type="text"
                  placeholder="Select Category"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={education.field_of_study}
                  onChange={(e) =>
                    handleChange(
                      "education",
                      index,
                      "field_of_study",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="w-1/2 pr-1 mb-4 md:mb-10">
                <label className="block text-gray-500">Grade</label>
                <input
                  type="text"
                  placeholder="Grade"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={education.grade}
                  onChange={(e) =>
                    handleChange("education", index, "grade", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-gray-500">Date of Start</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={education.school_start_date}
                  onChange={(e) =>
                    handleChange(
                      "education",
                      index,
                      "school_start_date",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="w-1/2">
                <label className="block text-gray-500">Date of End</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={education.school_end_date}
                  onChange={(e) =>
                    handleChange(
                      "education",
                      index,
                      "school_end_date",
                      e.target.value
                    )
                  }
                  min={education.school_start_date}
                />
              </div>
            </div>
          </div>
        ))}
        <button
          className="w-1/2 max-w-[200px] md:px-4 py-2 border border-black rounded-lg cursor-pointer mb-10"
          onClick={handleAddEducation}
        >
          Add Education
        </button>
        {!isMobileView && <UpdateProfileBtn handleUpdate={handleUpdate} />}
      </div>
    )
  );
};

export default EducationInfo;
