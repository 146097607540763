import React, { useContext, useEffect, useRef, useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import BoltIcon from "@mui/icons-material/Bolt";
import CommentIcon from "@mui/icons-material/Comment";
import { BusinessContext } from "../../context/businessContext";
import { UserContext } from "../../context/userContext";
import { format, formatDistanceToNow, differenceInHours } from "date-fns";
import {
  getLikeCount,
  //  likeBusiness 
} from "../../utils/apiFunctions";
import dummyImgPost from "../../assets/noimage.jpg";
import VerifiedBadge from "./VerifiedBadge";
import {
  LinearProgress,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import RatingBox from "./RatingBox";
import dummyImg from "../../assets/dummyProfile.jpg";
import CommentSection from "./CommentSection";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
// import CloseIcon from "@mui/icons-material/Close";

const BusinessItem = () => {
  const [expandedPosts, setExpandedPosts] = useState({});
  const [visibleBox, setVisibleBox] = useState(null);
  const { userBusiness } = useContext(BusinessContext);
  const { user } = useContext(UserContext);
  const [likes, setLikes] = useState({});
  // const [liked, setLiked] = useState({});
  const navigate = useNavigate();
  const commentBoxRef = useRef(null);

  // const [isRatingDialogOpen, setIsRatingDialogOpen] = useState(false);

  const totalFields = [
    "_id",
    "name",
    "location",
    "industry",
    "category",
    "logo",
    "postImages",
    "video",
    "service_description",
    "slogan",
    "company_vision",
    "company_mission",
    "company_created_date",
    "company_address_line_one",
    "company_address_line_two",
    "company_province",
    "company_district",
    "postal_code",
    "contact_number",
    "website",
    "linkedin",
    "facebook",
    "whatsapp",
  ];

  const calculateCompletionPercentage = (post) => {
    let filledFields = 1;
    totalFields.forEach((field) => {
      if (post[field] && post[field].length > 0) {
        filledFields += 1;
      }
    });
    return (filledFields / totalFields.length) * 100;
  };

  const handleBoxVisibility = (boxType) => {
    setVisibleBox((prev) => (prev === boxType ? null : boxType));
  };

  // const handleLike = async (id) => {
  //   if (user) {
  //     try {
  //       const action = liked[id] ? "unlike" : "like";
  //       await likeBusiness(id, user._id, action);
  //       setLiked((prevLiked) => ({ ...prevLiked, [id]: !prevLiked[id] }));
  //       const response = await getLikeCount(id);
  //       setLikes((prevLikes) => ({ ...prevLikes, [id]: response.like_count }));
  //     } catch (error) {
  //       console.error("Error liking/unliking the business:", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (userBusiness) {
  //     const likedStatus = userBusiness.reduce((acc, post) => {
  //       acc[post._id] = post.likes.includes(user._id);
  //       return acc;
  //     }, {});
  //     setLiked(likedStatus);
  //   }
  // }, [userBusiness, user]);

  useEffect(() => {
    const fetchLikeCounts = async () => {
      try {
        const likeCounts = await Promise.all(
          userBusiness.map((post) => getLikeCount(post._id))
        );
        const likesMap = likeCounts.reduce((acc, curr, index) => {
          acc[userBusiness[index]._id] = curr.like_count;
          return acc;
        }, {});
        setLikes(likesMap);
      } catch (error) {
        console.error("Error fetching like counts:", error);
      }
    };

    fetchLikeCounts();
  }, [userBusiness]);

  const handleContinue = (post) => {
    navigate("/business/edit", { state: { post } });
    window.location.reload();
  };

  const toggleDescription = (id) => {
    setExpandedPosts((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  // const handleRatingDialogOpen = () => {
  //   setIsRatingDialogOpen(true);
  // };

  // const handleRatingDialogClose = () => {
  //   setIsRatingDialogOpen(false);
  // };

  if (!userBusiness || !user) {
    return <div>Loading</div>;
  }

  const sortedBusinesses = userBusiness.sort(
    (a, b) => new Date(b.post_date) - new Date(a.post_date)
  );

  return (
    <>
      {sortedBusinesses.map((post) => {
        const postDate = new Date(post.post_date);
        const now = new Date();
        const hoursDifference = differenceInHours(now, postDate);

        const formattedDate =
          hoursDifference < 24
            ? formatDistanceToNow(postDate, { addSuffix: true })
            : format(postDate, "MMMM d, yyyy");

        const completionPercentage = calculateCompletionPercentage(post);

        const isExpanded = expandedPosts[post._id];

        return (
          <div
            key={post._id}
            className="w-full flex-grow mx-auto bg-white mb-6 flex flex-col rounded-xl shadow-lg"
          >
            <div className="w-full flex items-center justify-between p-4 border-b">
              <div className="flex items-center w-3/4 overflow-x-hidden">
                {post.logo ? (
                  <img
                    src={post.logo}
                    alt="business"
                    className="rounded-full w-12 h-12 mr-4"
                  />
                ) : (
                  <img
                    src={dummyImg}
                    alt="business"
                    className="rounded-full w-12 h-12 mr-4"
                  />
                )}
                <div>
                  <h2 className="font-bold">{post.name}</h2>
                  <div className="flex">
                    <p className="text-xs text-gray-500">{formattedDate}</p>
                    <div className="ml-0.5">
                      <VerifiedBadge />
                    </div>
                  </div>
                </div>
              </div>
              {completionPercentage >= 100 ? (
                <div className="w-1/4 flex justify-center items-center">
                  <TaskAltOutlinedIcon
                    sx={{ color: "#1BE116", marginRight: 1, fontSize: 17 }}
                  />
                  <p
                    onClick={() => handleContinue(post)}
                    style={{ color: "#1BE116" }}
                    className="text-sm cursor-pointer"
                  >
                    Completed
                  </p>
                </div>
              ) : (
                <div className="w-1/4 flex flex-col justify-center items-">
                  <div className="flex items-center">
                    <LinearProgress
                      className="w-full rounded-lg mr-2"
                      variant="determinate"
                      value={Math.floor(completionPercentage)}
                    />
                    <h1 className="text-blue-600 text-xs">
                      {Math.floor(completionPercentage)}%
                    </h1>
                  </div>
                  <button
                    className="text-xs p-1 rounded-md text-blue-600 mt-1 border border-blue-600 mx-auto px-3"
                    onClick={() => handleContinue(post)}
                  >
                    Continue
                  </button>
                </div>
              )}
            </div>
            <div className="w-full p-4 flex flex-grow flex-col items-center">
              <p className="w-full text-gray-700 overflow-x-hidden">
                {post.service_description && (
                  <>
                    {isExpanded
                      ? post.service_description
                      : `${post.service_description.slice(0, 190)}...`}
                    {post.service_description.length > 190 && (
                      <button
                        className="text-blue-500 mt-2 self-start"
                        onClick={() => toggleDescription(post._id)}
                      >
                        {isExpanded ? "See less" : "See more"}
                      </button>
                    )}
                  </>
                )}
              </p>

              {post.video ? (
                <video
                  src={`${post.video}`}
                  alt="Post Media"
                  className="w-full mt-4 rounded-lg"
                  controls
                  muted
                >
                  <source src={`${post.video}`} />
                </video>
              ) : (
                <img
                  src={dummyImgPost}
                  alt="Post Media"
                  className="w-1/3 mt-4 rounded-lg"
                />
              )}
            </div>
            <div className="w-full flex items-center justify-between px-6 py-3 border-t">
              <div className="text-gray-600 flex items-center">
                <span className="font-medium text-gray-600">
                  {likes[post._id]}
                </span>
                <ThumbUpIcon className="text-blue-500 ml-3" />
                {/* <BoltIcon className="text-yellow-500 -ml-1" /> */}
                {/* <CommentIcon className="text-green-500 -ml-1" /> */}
              </div>
              {post.average_rating && (
                <div className="text-gray-600 flex items-center">
                  <span className="font-medium text-gray-600">
                    {post.average_rating.toFixed(2)}
                  </span>
                  <BoltIcon className="text-yellow-500 -ml-1" />
                </div>
              )}
              <div className="text-gray-600">
                {post.comments?.length || 0} Comment
              </div>
            </div>
            {user && (
              <div className="w-full flex items-center justify-evenly py-3 border-t">
                {/* <button
                  onClick={() => handleLike(post._id)}
                  className="flex items-center cursor-pointer border border-gray-300 px-4 py-2 rounded-full bg-white hover:bg-gray-100 interaction-button"
                >
                  {liked[post._id] ? (
                    <ThumbUpIcon className="text-blue-500 mr-2" />
                  ) : (
                    <ThumbUpIcon className="text-gray-500 mr-2" />
                  )}
                  <p className="text-gray-600 hidden lg:block">Up</p>
                </button> */}
                {/* <button
                  onClick={handleRatingDialogOpen}
                  className="flex items-center border border-gray-300 px-4 py-2 rounded-full bg-white hover:bg-gray-100 interaction-button"
                >
                  <BoltIcon className="text-yellow-500 mr-2" />
                  <p className="text-gray-600 hidden lg:block">Rating</p>
                </button> */}
                <button
                  onClick={() => handleBoxVisibility("comment")}
                  className="flex items-center border border-gray-300 px-4 py-2 rounded-full bg-white hover:bg-gray-100 interaction-button"
                >
                  <CommentIcon className="text-green-500 mr-2" />
                  <p className="text-gray-600 hidden lg:block">Comment</p>
                </button>
              </div>
            )}
            {visibleBox === "comment" && (
              <div
                ref={commentBoxRef}
                className="w-full p-4 border-t interaction-box"
              >
                <CommentSection postId={post._id} />
              </div>
            )}

            {/* <Dialog open={isRatingDialogOpen} onClose={handleRatingDialogClose}>
              <DialogTitle>
                Rate this Service
                <IconButton
                  aria-label="close"
                  onClick={handleRatingDialogClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <RatingBox
                  postId={post._id}
                  setIsRatingDialogOpen={setIsRatingDialogOpen}
                />
              </DialogContent>
            </Dialog> */}
          </div>
        );
      })}
    </>
  );
};

export default BusinessItem;
