import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Business API functions

// Create a new Business
export const createBusiness = async (formData) => {
  const data = new FormData();
  Object.keys(formData).forEach((key) => {
    data.append(key, formData[key]);
  });

  try {
    const response = await axios.post(`${BASE_URL}/business`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating business:", error);
    throw error;
  }
};

// Get all Businesses

export const getBusinesses = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/business`);
    return response.data.businesses;
  } catch (error) {
    console.error("Error getting businesses:", error);
    throw error;
  }
};

// Get a single Business by ID
export const getBusinessById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/business/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error getting business:", error);
    throw error;
  }
};

// Update a Business by ID
export const updateBusiness = async (id, updatedData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/business/${id}`,
      updatedData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating business:", error);
    throw error;
  }
};
// Business Comments
export const addComment = async (id, formData) => {
  try {
    const response = await axios.put(`${BASE_URL}/business/${id}/comment`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return response.data;
  } catch (error) {
    console.error("Error liking business:", error);
    throw error;
  }
}

// Get all the comments
export const getComments = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/business/${id}/comments`)
    return response.data;
  } catch (error) {
    console.error("Error liking business:", error);
    throw error;
  }
}

// Rate a business
export const rateBusiness = async (businessId, formData) => {
  try {
    const response = await axios.put(`${BASE_URL}/business/${businessId}/rating`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error rating business:", error);
    throw error;
  }
};

// Business Likes
export const likeBusiness = async (id, user_id) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/business/${id}/like`,
      { user_id },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error liking business:", error);
    throw error;
  }
};

// get like count
export const getLikeCount = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/business/${id}/like_count`)
    return response.data
  } catch (error) {
    console.error("Error liking business:", error);
    throw error;
  }
}


// Delete a Business by ID
export const deleteBusiness = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/business/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting business:", error);
    throw error;
  }
};

// User API functions

export const createUser = async (formData) => {
  const data = new FormData();
  Object.keys(formData).forEach((key) => {
    data.append(key, formData[key]);
  });

  try {
    const response = await axios.post(`${BASE_URL}/user`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating user:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Get all Users
export const getUsers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/user`);
    return response.data.users;
  } catch (error) {
    console.error("Error getting users:", error);
    throw error;
  }
};

// Get a User by ID
export const getUserById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/user/${id}`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error getting user:", error);
    throw error;
  }
};

// Get a User by Email
export const getUserByEmail = async (email) => {
  try {
    const response = await axios.get(`${BASE_URL}/user/email/${email}`);
    return response.data;
  } catch (error) {
    console.error("Error getting user:", error);
    throw error;
  }
};

// Update a User by ID
export const updateUser = async (id, updatedData) => {
  try {
    const response = await axios.put(`${BASE_URL}/user/${id}`, updatedData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

// Delete a User by ID
export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/user/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

// Verify User Password
export const verifyUserPassword = async (email, password) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/user/${email}/verify`,
      { password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying user password:", error);
    throw error;
  }
};

// Post API functions

// Create a new Post
export const createPost = async (postData) => {
  try {
    const response = await axios.post(`${BASE_URL}/post`, postData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating post:", error);
    throw error;
  }
};

// Get all Posts
export const getPosts = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/post`);
    return response.data.posts;
  } catch (error) {
    console.error("Error getting posts:", error);
    throw error;
  }
};

// Get a Post by ID
export const getPostById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/post/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error getting post:", error);
    throw error;
  }
};

// Update a Post by ID
export const updatePost = async (id, updatedData) => {
  try {
    const response = await axios.put(`${BASE_URL}/post/${id}`, updatedData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating post:", error);
    throw error;
  }
};

// Delete a Post by ID
export const deletePost = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/post/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting post:", error);
    throw error;
  }
};


// Promotions API
// add a Promotion
export const addPromotion = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/promotion`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return response.data;
  } catch (error) {
    console.error("Error creating promotion:", error);
    throw error;
  }
}

// get all promotions
export const getAllPromotions = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/promotions`)
    return response.data.promotions

  }
  catch (error) {
    console.error("Error getting promotion:", error);
    throw error;
  }
}