import React, { useContext } from "react";
import PostItem from "../../../components/widgits/PostItem";
import { BusinessContext } from "../../../context/businessContext";
import { UserContext } from "../../../context/userContext";

const HomeContent = () => {
  const { businesses } = useContext(BusinessContext);
  const { allUsers, searchTerm } = useContext(UserContext);

  if (!businesses || !allUsers) {
    return <div>Loading...</div>;
  }

  // Filter and sort businesses based on search term and post date
  const filteredBusinesses = businesses
    .filter((post) => {
      const user = allUsers.find((user) => user._id === post.user_id);
      if (!user) return false;
      const postContent =
        `${post.name}`.toLowerCase();
      return postContent.includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => new Date(b.post_date) - new Date(a.post_date));

  return (
    <div className="w-full flex flex-col items-center mt-9 mb-28">
      {filteredBusinesses.map((post) => (
        <PostItem key={post._id} post={post} />
      ))}
    </div>
  );
};

export default HomeContent;
