import React from "react";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { Box } from "@mui/material";

const PromotionCard = ({ promotion }) => {

  return (
    <a href={promotion.promotionUrl} target="_blank" rel="noopener noreferrer">
      <Card sx={{ minHeight: "280px", width: "100%" }}>
        <CardCover>
          {promotion.media_type === 'image' ?
            <img src={promotion.media[0]} alt={promotion.title} loading="lazy" />
            :
            <video src={promotion.media[0]} autoPlay loop muted />
          }
        </CardCover>
        <CardCover
          sx={{
            background:
              "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
          }}
        />
        <CardContent sx={{ justifyContent: "flex-end" }}>
          <Box className="bg-[#1D9BF0] py-1 w-24 items-center text-center rounded-md">
            {/* sx={{ mb: 1, backgroundColor: "#1D9BF0", paddingX: 3, paddingY: 1, }} */}
            <Typography level="title-lg" textColor="#fff">
              {promotion.discountValue}% off
            </Typography>
          </Box>
          <Typography level="title-lg" textColor="#fff">
            {promotion.promotionName}
          </Typography>
          <Typography
            //   startDecorator={<LocationOnRoundedIcon />}
            textColor="neutral.300"
          >
            {promotion.description}
          </Typography>
        </CardContent>
      </Card>
    </a>
  )
};

export default PromotionCard;
