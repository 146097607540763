import React, { createContext, useState, useEffect } from "react";
import { getUserById, getUsers } from "../utils/apiFunctions";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalSteps, setTotalSteps] = useState(28);
  const [stepsCompleted, setStepsCompleted] = useState(5);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserId(token);
    }
  }, []);

  // get logged user
  useEffect(() => {
    if (userId) {
      const fetchUser = async (id) => {
        try {
          const response = await getUserById(id);
          setUser(response);
        } catch (error) {
          console.log("Error fetching user:", error);
        }
      };

      fetchUser(userId);
    }
  }, [userId]);

  // get all users
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await getUsers();
        setAllUsers(response);
      } catch (error) {
        console.log("Error fetching users:", error);
      }
    };
    fetchAllUsers();
  }, []);

  // console.log(userId);
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        allUsers,
        searchTerm,
        setSearchTerm,
        userId,
        totalSteps,
        stepsCompleted,
        setTotalSteps,
        setStepsCompleted,
        isMobileView,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
