import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
import { UserContext } from "../../../context/userContext";
import profileImg from "../../../assets/dummyProfile.jpg";
import { IoCubeOutline } from "react-icons/io5";

// Function to format the created_at date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", { month: "short", year: "numeric" });
};

const ProfileHeader = () => {
  const { user } = useContext(UserContext);

  // "created_at": "2024-09-22 19:29:00",

  // user.created_at  =

  if (!user) {
    return (
      <Box
        className="hidden md:flex w-1/4 p-4"
        display="flex"
        flexDirection="column"
        alignItems="center"
      />
    );
  }

  const profileImageUrl = user.profile_picture
    ? `${user.profile_picture}`
    : profileImg;

  return (
    <Box className="w-full mt-4 md:mt-12 flex flex-col items-center rounded-lg shadow-lg overflow-hidden">
      <Box className="w-full bg-gradient-to-b from-gray-500 to-white p-6 flex flex-col items-center rounded-t-lg">
        <img
          src={profileImageUrl}
          alt="Profile"
          className="rounded-full w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 border-4 border-white shadow-md"
        />
        <Typography variant="h6" className="font-bold mt-4 text-gray-800">
          {user.first_name} {user.last_name}
        </Typography>
      </Box>
      <Box className="w-full bg-white p-6 flex flex-col items-center rounded-b-lg">
        <Box className="flex flex-row mt-2 space-x-8 text-gray-600">
          <Box className="flex flex-row items-center">
            <IoCubeOutline className="mr-2 text-gray-500" />
            <Typography>{user.job_title}</Typography>
          </Box>
          <Box className="flex flex-row items-center">
            <FaMapMarkerAlt className="mr-2 text-gray-500" />
            <Typography>{user.company} </Typography>
          </Box>
          <Box className="flex flex-row items-center">
            <FaCalendarAlt className="mr-2 text-gray-500" />
            <Typography>Joined {formatDate(user.created_at)}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileHeader;
