import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
// import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { CameraAltOutlined, LocationOnOutlined } from "@mui/icons-material";
import axios from "axios";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// New Component: MapDialog
const MapDialog = ({ formData, open, handleClose, onSelectLocation }) => {
  const [position, setPosition] = useState([6.9271, 79.8612]); // Default coordinates (Colombo)
  const defaultIcon = L.icon({
    iconUrl: "https://unpkg.com/leaflet/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  // Parse formData.location if available, and set as the initial location
  useEffect(() => {
    if (formData.location) {
      const [lat, lng] = formData.location.split(",").map(Number); // Split and convert to numbers
      setPosition([lat, lng]);
    }
  }, [formData.location]);

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        setPosition([e.latlng.lat, e.latlng.lng]); // Update position on map click
      },
    });
    return null;
  };

  // Function to handle location selection
  const handleSelect = () => {
    const selectedLocation = `${position[0]},${position[1]}`; // Convert array to a string
    onSelectLocation(selectedLocation); // Send selected location back to formData
    handleClose(); // Close the modal
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <div className="p-4">
        <h2 className="text-lg mb-4">Select Location</h2>
        <MapContainer
          center={position} // Use the current position as the center
          zoom={13}
          style={{ height: "400px", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <MapEvents /> {/* Handle map click events */}
          <Marker position={position} icon={defaultIcon} />{" "}
          {/* Display marker with custom icon */}
        </MapContainer>
        <div className="flex justify-end mt-4">
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSelect}
            variant="contained"
            color="primary"
            className="ml-2"
          >
            Select
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const BusinessInfo = ({ formData, handleChange, handleFileChange }) => {
  // const { enqueueSnackbar } = useSnackbar();
  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreviews, setImagePreviews] = useState();
  const [videoPreview, setVideoPreview] = useState(null);
  const [logoProgress, setLogoProgress] = useState(0);
  const [imageProgress, setImageProgress] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [openMapDialog, setOpenMapDialog] = useState(false);

  const cloudName = process.env.REACT_APP_CLOUD_NAME;
  const uploadLogoPreset = process.env.REACT_APP_BUSINESS_LOGO_UPLOAD_PRESET;
  const uploadVideoPreset = process.env.REACT_APP_BUSINESS_VIDEO_UPLOAD_PRESET;
  const uploadImagesPreset = process.env.REACT_APP_BUSINESS_IMAGE_UPLOAD_PRESET;

  const openMap = () => {
    setOpenMapDialog(true);
  };

  const handleLocationSelect = (location) => {
    handleChange("location", location); // Update the location in formData
  };

  const categories = [
    "Government Business",
    "Group Business",
    "Private Business",
  ];

  const industries = [
    "Manufacturing",
    "Agriculture",
    "Management",
    "Construction",
    "Technology",
    "Health Care",
    "Food Industry",
    "Education",
    "Entertainment",
    "Clothing Industry",
  ];

  useEffect(() => {
    if (formData.logo) {
      setImagePreview(formData.logo);
    }
    if (formData.postImages?.length) {
      setImagePreviews(formData.postImages);
    }
    if (formData.video) {
      setVideoPreview(formData.video);
    }
  }, [formData]);

  const uploadLogo = async (file) => {
    const formDataLogos = new FormData();
    formDataLogos.append("file", file);
    formDataLogos.append("upload_preset", uploadLogoPreset);
    formDataLogos.append("folder", "business_logos");

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formDataLogos,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setLogoProgress(progress);
          },
        }
      );
      handleChange("logo", res.data.secure_url);
    } catch (error) {
      console.error("error uploading logo image :", error);
    }
  };

  const uploadVideo = async (files) => {
    const formDataMedia = new FormData();
    formDataMedia.append("file", files[0]);
    formDataMedia.append("upload_preset", uploadVideoPreset);
    formDataMedia.append("folder", "business_videos");

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/video/upload`,
        formDataMedia,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setVideoProgress(progress);
          },
        }
      );
      handleChange("video", res.data.secure_url);
    } catch (error) {
      console.error("error uploading video :", error);
    }
  };

  const uploadImages = async (files) => {
    // if (files.length + imagePreviews.length > 5) {
    //   enqueueSnackbar("You can only upload up to 5 images.", {
    //     variant: "error",
    //   });
    //   return;
    // }

    const formDataMedia = new FormData();
    files.forEach((file) => {
      formDataMedia.append("file", file);
      formDataMedia.append("upload_preset", uploadImagesPreset);
      formDataMedia.append("folder", "business_images");
    });

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formDataMedia,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setImageProgress(progress);
          },
        }
      );
      handleChange("postImages", res.data.secure_url);
      setImagePreviews(res.data.secure_url);
    } catch (error) {
      console.error("error uploading images :", error);
    }
  };

  const DropzoneComponent = ({ name, accept, filesLimit }) => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: (acceptedFiles) => {
        if (name === "postImages") {
          uploadImages(acceptedFiles);
        } else if (name === "video") {
          uploadVideo(acceptedFiles);
        }
      },
      accept,
      maxFiles: filesLimit,
      multiple: filesLimit > 1,
    });

    return (
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} name={name} />
        <p className="p-4">
          Drag & drop some files here, or click to select files
        </p>
      </div>
    );
  };

  const ProgressBar = ({ progress }) => (
    <div className="relative pt-1">
      <div className="flex mb-2 items-center justify-between">
        <div>
          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
            Uploading
          </span>
        </div>
        <div className="text-right">
          <span className="text-xs font-semibold inline-block text-blue-600">
            {progress}%
          </span>
        </div>
      </div>
      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
        <div
          style={{ width: `${progress}%` }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
        ></div>
      </div>
    </div>
  );

  return (
    <form className="w-full mx-auto flex flex-col justify-between rounded-xl">
      <div className="flex w-full flex-col justify-between md:mb-8">
        <div className="flex w-full flex-row-reverse justify-between">
          <div className="w-1/2 flex flex-col md:items-center mt-6 items-end mr-3">
            <label className="text-sm md:text-base" htmlFor="logo">
              Business Logo
            </label>
            <label className="font-bold" htmlFor="logo">
              <div className="w-16 h-16 bg-gray-300 border-black border mt-4 flex items-center justify-center p-2 cursor-pointer rounded-full">
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="logo"
                    className="rounded-full w-full h-full"
                  />
                ) : (
                  <CameraAltOutlined style={{ fontSize: "30px" }} />
                )}
              </div>
            </label>
            <input
              id="logo"
              type="file"
              name="logo"
              onChange={(e) => uploadLogo(e.target.files[0])}
              hidden
            />
            {logoProgress > 0 && <ProgressBar progress={logoProgress} />}
          </div>
          <div className="flex w-full flex-col justify-between mb-4">
            <div className="flex flex-col mt-4 w-full">
              <label htmlFor="name" className="text-lg mb-2">
                Business Name
              </label>
              <input
                value={formData.name}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                name="name"
                className="w-full p-3 rounded-md border border-gray-300"
                type="text"
                placeholder="Business Name"
              />
            </div>
            <div className="flex flex-col mt-4 w-full">
              <label className="text-lg mb-2">Industry</label>
              <FormControl fullWidth>
                <InputLabel id="industry-label">{formData.industry}</InputLabel>
                <Select
                  labelId="industry-label"
                  id="industry-select"
                  value={formData.industry}
                  label="Select an industry"
                  onChange={(e) => handleChange("industry", e.target.value)}
                  name="industry"
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {industries.map((industry, index) => (
                    <MenuItem key={index} value={industry}>
                      {industry}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-col mt-4 w-full">
              <label className="text-lg mb-2">Type of Business</label>
              <FormControl fullWidth>
                <InputLabel id="category-label">{formData.category}</InputLabel>
                <Select
                  labelId="category-label"
                  id="category-select"
                  value={formData.category}
                  label="Select a category"
                  onChange={(e) => handleChange("category", e.target.value)}
                  name="category"
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((category, index) => (
                    <MenuItem key={index} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-col mt-4 w-full">
              <label htmlFor="location" className="text-lg mb-2">
                Location
              </label>
              <FormControl fullWidth>
                <Button
                  variant="outlined"
                  endIcon={<LocationOnOutlined />}
                  onClick={openMap}
                  style={{
                    justifyContent: "flex-start",
                    padding: "10px 14px",
                    textTransform: "none",
                  }}
                >
                  {formData.location
                    ? `Selected: ${formData.location}`
                    : "Select Location"}
                </Button>
              </FormControl>
            </div>
          </div>
        </div>
        <label className="text-lg mb-2">Upload Video</label>
        <div className="flex w-full md:w-5/6 mb-6 items-center justify-center border cursor-pointer border-x-4 border-y-4 ml-0.5 border-blue-500 border-dashed rounded-lg">
          <DropzoneComponent name="video" accept="video/*" filesLimit={1} />
        </div>
        {videoProgress > 0 && <ProgressBar progress={videoProgress} />}
        {videoPreview && (
          <div className="flex flex-col justify-center mb-6">
            <video src={videoPreview} controls className="w-full rounded-lg" />
          </div>
        )}
        <label className="text-lg mb-2">Upload Images (Max 5)</label>
        <div className="flex flex-row w-full md:w-5/6 mb-6 items-center justify-center cursor-pointer border border-x-4 border-y-4 ml-0.5 border-blue-500 border-dashed rounded-lg">
          <DropzoneComponent
            name="postImages"
            accept="image/*"
            filesLimit={5}
          />
        </div>
        {imageProgress > 0 && <ProgressBar progress={imageProgress} />}
        {imagePreviews && (
          <div className="flex flex-wrap justify-center md:mb-6">
            <img
              src={imagePreviews}
              alt={imagePreviews}
              className="w-32 h-32 object-cover m-1 rounded-lg"
            />
          </div>
        )}
      </div>
      <MapDialog
        open={openMapDialog}
        handleClose={() => setOpenMapDialog(false)}
        formData={formData} // Pass formData to modal
        onSelectLocation={handleLocationSelect} // Pass location select function
      />
    </form>
  );
};

export default BusinessInfo;
