import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/userContext";

const ProfileCompletion = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const { user, setTotalSteps, setStepsCompleted } = useContext(UserContext);

  const defaultFormData = {
    profile_picture: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    address: "",
    nic: "",
    dob: "",
    about: "",
    job_title: "",
    company: "",
    company_description: "",
    education: [
      {
        school: "",
        degree: "",
        field_of_study: "",
        grade: "",
        school_start_date: "",
        school_end_date: "",
      },
    ],
    skills: [
      {
        skill: "",
        where_used_the_skill: "",
        certificate_name: "",
        certificate_organization: "",
        certificate_issue_date: "",
        certificate_expire_date: "",
        certificate_url: "",
      },
    ],
    website: "",
    linkedin: "",
    facebook: "",
    whatsapp: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    if (user) {
      setFormData({
        profile_picture: user.profile_picture || "",
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
        mobile: user.mobile || "",
        address: user.address || "",
        nic: user.nic || "",
        dob: user.dob || "",
        about: user.about || "",
        job_title: user.job_title || "",
        company: user.company || "",
        company_description: user.company_description || "",
        education: user.education?.length
          ? user.education
          : defaultFormData.education,
        skills: user.skills?.length ? user.skills : defaultFormData.skills,
        website: user.website || "",
        linkedin: user.linkedin || "",
        facebook: user.facebook || "",
        whatsapp: user.whatsapp || "",
      });
    }
  }, [user, defaultFormData.education, defaultFormData.skills]);

  const countFilledFields = (data) => {
    let filledFields = 0;

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => {
          filledFields += Object.values(item).filter(
            (value) => value && value !== "null"
          ).length;
        });
      } else if (data[key] && data[key] !== "null") {
        filledFields++;
      }
    });

    setStepsCompleted(filledFields);
    setTotalSteps(totalSteps);
    return filledFields;
  };

  const totalSteps = Object.keys(formData).reduce((acc, key) => {
    if (Array.isArray(formData[key])) {
      return acc + formData[key].length * Object.keys(formData[key][0]).length;
    }
    return acc + 1;
  }, 0);

  const stepsCompleted = countFilledFields(formData);

  const editProfile = () => {
    navigate("/profile/edit");
  };

  return (
    <div className="flex items-center bg-white p-4 rounded-lg shadow-lg w-full mt-5 mx-auto">
      <Box
        className="flex items-center justify-center p-2 rounded-lg"
        sx={{
          width: isSm ? 40 : isMd ? 50 : 60,
          height: isSm ? 40 : isMd ? 50 : 60,
          backgroundColor: "blue.100",
        }}
      >
        <TaskAltOutlinedIcon
          color="primary"
          sx={{ fontSize: isSm ? 30 : isMd ? 40 : 45 }}
        />
      </Box>
      <Box className="flex-grow ml-4">
        <Box
          sx={{
            fontSize: isSm ? "0.75rem" : "1rem",
            fontWeight: "bold",
          }}
        >
          Complete your profile
        </Box>
        <LinearProgress
          className="py-1.5 my-2 rounded-lg"
          sx={{
            width: isSm ? "80%" : isMd ? "90%" : "95%",
            height: isSm ? 6 : 10,
          }}
          variant="determinate"
          value={(stepsCompleted / totalSteps) * 100}
        />
        <Box
          className="text-gray-600"
          sx={{ fontSize: isSm ? "0.75rem" : "1rem" }}
        >
          {stepsCompleted} of {totalSteps} Steps completed
        </Box>
      </Box>
      <Button
        onClick={editProfile}
        variant="outlined"
        color="primary"
        sx={{ marginLeft: isSm ? 2 : 4 }}
      >
        Continue
      </Button>
    </div>
  );
};

export default ProfileCompletion;
