import React, { useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useMediaQuery, useTheme, Divider } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { UserContext } from "../../../context/userContext";

export default function Searchbar() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { setSearchTerm } = useContext(UserContext);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center mt-5">
      <Autocomplete
        freeSolo
        id="search-bar"
        disableClearable
        options={[]} 
        getOptionLabel={() => ""} 
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            sx={{ color: "#BDBEBE" }}
            {...params}
            label="Search"
            placeholder="Search"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "#BDBEBE" }} />
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      mx: 2,
                      height: 28,
                      alignSelf: "center",
                      borderColor: "#E0E0E0",
                      width: 3,
                    }}
                  />
                  <IconButton>
                    <TuneIcon sx={{ color: "#BDBEBE" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        sx={{
          width: isSm ? "100%" : "100%",
          backgroundColor: "white",
          mx: "auto",
          boxShadow: 3,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "lightgray",
            },
            "&.Mui-focused fieldset": {
              borderColor: "gray",
            },
          },
          borderRadius: 2,
        }}
      />
    </div>
  );
}
