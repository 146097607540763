import React from 'react';
import { useNavigate } from 'react-router-dom';
import promoBgImg from "../../assets/promotionBackground.png";

const PromotionBg = () => {
    const navigate = useNavigate();

    return (
        <div className="relative w-full h-auto rounded-md overflow-hidden">
            {/* Responsive SVG */}
            <svg
                width="100%"
                height="auto"
                viewBox="0 0 642 136"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-auto"
            >
                <path d="M1.90735e-05 148C-75.0796 146.429 0.000278473 63.1071 0.000278473 63.1071V13H681V117.361C681 117.361 804.571 306.289 723.5 296C631.209 284.287 105.549 150.209 1.90735e-05 148Z" fill="url(#paint0_linear_0_1)" />
                <path d="M106.5 10C33.5151 8.42414 -9 50.2603 -9 50.2603V0H653V104.681C653 104.681 578.81 131.321 500 121C410.284 109.251 209.104 12.2154 106.5 10Z" fill="url(#paint1_linear_0_1)" />
                <defs>
                    <linearGradient id="paint0_linear_0_1" x1="339.419" y1="51.1773" x2="339.458" y2="117.68" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B7EAFB" />
                        <stop offset="1" stopColor="#487593" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_0_1" x1="320.949" y1="38.2941" x2="320.99" y2="105.001" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#79C6F9" />
                        <stop offset="1" stopColor="#487593" />
                    </linearGradient>
                </defs>
            </svg>

            {/* Overlay Content */}
            <div className="absolute inset-0 flex justify-between h-full gap-10">
                <img
                    src={promoBgImg}
                    alt="Create an Account"
                    className=""
                />
                <div className="mr-16 flex justify-around h-full flex-col w-full items-start">
                    <p className="text-white font-medium text-[16px]">
                        Promotion & Create Account CTA "Enjoy exclusive
                        <br />
                        promotions by
                        <br />
                        creating an account!"
                    </p>
                    <button
                        className="px-6 py-1 bg-[#262B61] text-white rounded-md transition"
                        onClick={() => navigate("/signup")}
                    >
                        Create Account
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PromotionBg;
