import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import PromotionCard from "../../../components/widgits/PromotionItemCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllPromotions } from "../../../utils/apiFunctions";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"; // For custom buttons
import { UserContext } from "../../../context/userContext";
import PromotionBg from "../../../assets/svg/promotionBg";

const PromotionContainer = () => {
  const [promotionData, setPromotionData] = useState([]);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null); // To handle errors
  const { user } = useContext(UserContext);


  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const fetchData = await getAllPromotions();
        setPromotionData(fetchData); // Set the fetched data to state
      } catch (err) {
        console.error("Error fetching promotions:", err);
        setError("Failed to load promotions."); // Set error if API call fails
      } finally {
        setLoading(false); // Ensure loading is turned off after the fetch
      }
    };

    fetchPromotions();
  }, []);

  // Custom Previous Button
  const PrevButton = ({ onClick }) => (
    <button
      className="hidden group-hover:flex items-center justify-center absolute left-0 top-0 h-full w-8 z-10 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all"
      onClick={onClick}
      style={{ border: "none" }}
    >
      <ArrowBackIos fontSize="small" style={{ color: "#333" }} />
    </button>
  );

  // Custom Next Button
  const NextButton = ({ onClick }) => (
    <button
      className="hidden group-hover:flex items-center justify-center absolute right-0 top-0 h-full w-8 z-10 bg-white bg-opacity-50 hover:bg-opacity-75 transition-all"
      onClick={onClick}
      style={{ border: "none" }}
    >
      <ArrowForwardIos fontSize="small" style={{ color: "#333" }} />
    </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
  };

  return (
    <div className="relative w-full mt-10 group">

      {user ? <></> : <div className="mb-2">
        <PromotionBg />
      </div>}

      {loading ? (
        <p>Loading...</p>
      ) : !error ? promotionData.length > 0 ? (
        <Slider {...settings}>
          {promotionData.map((promotion) => (
            <div key={promotion._id} className="relative group">
              <PromotionCard promotion={promotion} />
            </div>
          ))}
        </Slider>
      ) : (
        <p>No promotions available.</p>
      )
        :
        (
          <p>{error}.</p>
        )
      }
    </div>
  );
};

export default PromotionContainer;
