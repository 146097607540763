import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../../context/userContext";
import UpdateProfileBtn from "./UpdateProfileBtn";

const AccountInfo = ({ formData, handleChange, handleUpdate }) => {
  const { user } = useContext(UserContext);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    user && (
      <div className="w-full flex py-7 flex-col items">
        {isMobileView && (
          <h1 className="text-xl mb-2 w-full font-semibold p-2">
            Account Information
          </h1>
        )}
        <div className="flex flex-col mb-4 w-full max-w-3xl">
          <div className="mb-4 md:mb-10">
            <label className="block text-gray-500">Website</label>
            <input
              type="text"
              placeholder="Enter your website URL here"
              className="w-full border border-gray-300 p-2 rounded"
              value={formData.website}
              onChange={(e) => handleChange("website", e.target.value)}
            />
          </div>
          <div className="mb-4 md:mb-10">
            <label className="block text-gray-500">LinkedIn</label>
            <input
              type="text"
              placeholder="Connect your LinkedIn account"
              className="w-full border border-gray-300 p-2 rounded"
              value={formData.linkedin}
              onChange={(e) => handleChange("linkedin", e.target.value)}
            />
          </div>
          <div className="mb-4 md:mb-10">
            <label className="block text-gray-500">Facebook</label>
            <input
              type="text"
              placeholder="Connect your Facebook account"
              className="w-full border border-gray-300 p-2 rounded"
              value={formData.facebook}
              onChange={(e) => handleChange("facebook", e.target.value)}
            />
          </div>
          <div className="mb-4 md:mb-10">
            <label className="block text-gray-500">WhatsApp Account No.</label>
            <input
              type="number"
              placeholder="WhatsApp number"
              maxLength={10}
              minLength={10}
              className="w-full border border-gray-300 p-2 rounded"
              value={formData.whatsapp}
              onChange={(e) => handleChange("whatsapp", e.target.value)}
            />
          </div>
          {!isMobileView && <UpdateProfileBtn handleUpdate={handleUpdate} />}
        </div>
      </div>
    )
  );
};

export default AccountInfo;
