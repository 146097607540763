import React from "react";

const ContactInfo = ({ formData, handleChange }) => {

 

  return (
    <div className="w-full flex flex-col items">
      <div className="flex flex-col mb-4">
        <div className="md:w-2/3 md:mb-10 mb-4">
          <label className="block text-gray-500">Contact Number</label>
          <input
            type="number"
            maxLength={10}
            minLength={9}
            value={formData.contact_number}
            onChange={(e) => handleChange("contact_number", e.target.value)}
            placeholder="Enter your business contact number"
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>
        <div className="md:w-2/3 md:mb-10 mb-4">
          <label className="block text-gray-500">Website</label>
          <input
            type="text"
            value={formData.website}
            onChange={(e) => handleChange("website", e.target.value)}
            placeholder="Enter your website URL here"
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>
        <div className="md:w-2/3 md:mb-10 mb-4">
          <label className="block text-gray-500">LinkedIn</label>
          <input
            type="text"
            value={formData.linkedin}
            onChange={(e) => handleChange("linkedin", e.target.value)}
            placeholder="Connect your LinkedIn account"
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>
        <div className="md:w-2/3 md:mb-10 mb-4">
          <label className="block text-gray-500">Facebook</label>
          <input
            type="text"
            value={formData.facebook}
            onChange={(e) => handleChange("facebook", e.target.value)}
            placeholder="Connect your Facebook account"
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>
        <div className="md:w-2/3 md:mb-10 mb-4">
          <label className="block text-gray-500">WhatsApp Account No.</label>
          <input
            type="number"
            value={formData.whatsapp}
            maxLength={10}
            minLength={9}
            onChange={(e) => handleChange("whatsapp", e.target.value)}
            placeholder="WhatsApp number"
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
