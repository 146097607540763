import { Box } from "@mui/material";
import React, { useState } from "react";

const EditProfileNavbar = ({ setCurrentState }) => {
  const [activeItem, setActiveItem] = useState("personalInfo");

  const handleItemClick = (item) => {
    setActiveItem(item);
    setCurrentState(item);
  };

  const menuItems = [
    { key: "personalInfo", label: "Personal Information" },
    { key: "professionalInfo", label: "Professional Information" },
    { key: "educationInfo", label: "Education Information" },
    { key: "accountInfo", label: "Account Information" },
    { key: "skillInfo", label: "Skills & Experience" },
  ];

  return (
    <Box className="w-full flex flex-col p-3">
      <ul className="list-none m-0 p-0">
        {menuItems.map((item) => (
          <li
            key={item.key}
            className={`py-4 cursor-pointer  text-center flex items-center ${
              activeItem === item.key ? "text-black font-bold" : "text-gray-500"
            }`}
            onClick={() => handleItemClick(item.key)}
          >
            {activeItem === item.key && (
              <span className="inline-block w-1 h-6 bg-black mr-2"></span>
            )}
            {item.label}
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default EditProfileNavbar;
