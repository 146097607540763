import { Box } from "@mui/material";
import React, { useState } from "react";

const EditBusinessNavbar = ({ setCurrentState }) => {
  const [activeItem, setActiveItem] = useState("businessInfo");

  const handleItemClick = (item) => {
    setActiveItem(item);
    setCurrentState(item);
  };

  const menuItems = [
    { key: "businessInfo", label: "Business Information" },
    { key: "companyDetails", label: "Company Details" },
    { key: "companyAddress", label: "Company Address" },
    { key: "contactInfo", label: "Contact Information" },
  ];

  return (
    <Box className="w-full flex flex-col p-3">
      <ul className="list-none m-0 p-0">
        {menuItems.map((item) => (
          <li
            key={item.key}
            className={`py-4 cursor-pointer  text-center flex items-center ${
              activeItem === item.key ? "text-black font-bold" : "text-gray-500"
            }`}
            onClick={() => handleItemClick(item.key)}
          >
            {activeItem === item.key && (
              <span className="inline-block w-1 h-6 bg-black mr-2"></span>
            )}
            {item.label}
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default EditBusinessNavbar;
