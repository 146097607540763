import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import LockIcon from "@mui/icons-material/Lock";
import HelpIcon from "@mui/icons-material/Help";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
const MobileMenu = ({ user, handleMobileMenuClose }) => {
  const location = useLocation();



  const menuItems = [
    { to: "/", icon: <HomeIcon />, text: "Home" },
    { to: "/profile", icon: <AccountCircleIcon />, text: "Profile" },
    { to: "/business", icon: <BusinessIcon />, text: "My Business" },
    {
      to: '/promotion', icon: <FontAwesomeIcon icon={faGift} />, text: "Promotion"
    },
    {
      to: "/notification",
      icon: <NotificationsIcon />,
      text: "Notifications",
    },
    { to: "/settings", icon: <SettingsIcon />, text: "Settings" },
    { to: "/privacy", icon: <LockIcon />, text: "Privacy and Data" },
    { to: "/faq", icon: <HelpIcon />, text: "FAQ" },
  ];

  const profileImageUrl = user.profile_picture
    ? `${user.profile_picture}`
    : null;

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#ffffff",
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          // mb: 1,
          backgroundColor: "#F2F6FB",
          padding: 2,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            src={profileImageUrl}
            alt={`${user.first_name} ${user.last_name}`}
            sx={{ width: 56, height: 56, mr: 2 }}
          />
          <Box>
            <Typography
              sx={{
                color: "#000000",
                fontWeight: 600,
                fontSize: 15,
              }}
              variant="h6"
            >
              {user.first_name} {user.last_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: 10,
              }}
              color="textSecondary"
            >
              {user.email}
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="large"
          aria-label="view compact"
          color="inherit"
          disableRipple
          disableFocusRipple
          onClick={handleMobileMenuClose}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <List>
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.to}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListItem
              onClick={handleMobileMenuClose}
              sx={{
                bgcolor: location.pathname === item.to ? "#F2F7FF" : "inherit",
                borderRadius: 1,
                marginBottom: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  color: "#000000",
                  fontWeight: 600,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: "#000000",
                  fontWeight: 600,
                }}
                primary={item.text}
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );
};

export default MobileMenu;
