import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const CompanyAddress = ({ formData, handleChange }) => {
  const provinces = [
    "Central",
    "Eastern",
    "Northern",
    "North Western",
    "North Central",
    "Sabaragamuwa",
    "Southern",
    "Uva",
    "Western",
  ];

  const districtsByProvince = {
    Central: ["Kandy", "Matale", "Nuwara Eliya"],
    Eastern: ["Ampara", "Batticaloa", "Trincomalee"],
    Northern: ["Jaffna", "Kilinochchi", "Mannar", "Vavuniya", "Mullaitivu"],
    "North Western": ["Kurunegala", "Puttalam"],
    "North Central": ["Anuradhapura", "Polonnaruwa"],
    Sabaragamuwa: ["Ratnapura", "Kegalle"],
    Southern: ["Galle", "Matara", "Hambantota"],
    Uva: ["Badulla", "Moneragala"],
    Western: ["Colombo", "Gampaha", "Kalutara"],
  };

  const [filteredDistricts, setFilteredDistricts] = useState([]);

  useEffect(() => {
    if (formData.company_province) {
      setFilteredDistricts(districtsByProvince[formData.company_province]);
    } else {
      setFilteredDistricts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.company_province]);

  return (
    <form className="w-full mx-auto flex flex-col justify-between rounded-xl md:mb-10">
      <div className="flex md:w-2/3 flex-col justify-between mb-4">
        <div className="flex flex-col mt-4 w-full">
          <label htmlFor="company_address_line_one" className="text-lg mb-2">
            Address Line 1 <span className="text-red-500 font-semibold">*</span>
          </label>
          <input
            required
            name="company_address_line_one"
            value={formData.company_address_line_one}
            onChange={(e) =>
              handleChange("company_address_line_one", e.target.value)
            }
            className="w-full p-3 rounded-md border border-gray-300"
            type="text"
            placeholder="Address Line 1"
          />
        </div>
        <div className="flex flex-col mt-4 w-full">
          <label htmlFor="company_address_line_two" className="text-lg mb-2">
            Address Line 2 <span className="text-red-500 font-semibold">*</span>
          </label>
          <input
            required
            name="company_address_line_two"
            value={formData.company_address_line_two}
            onChange={(e) =>
              handleChange("company_address_line_two", e.target.value)
            }
            className="w-full p-3 rounded-md border border-gray-300"
            type="text"
            placeholder="Address Line 2"
          />
        </div>
        <div className="flex flex-col mt-4 w-full">
          <label htmlFor="company_province" className="text-lg mb-2">
            Province <span className="text-red-500 font-semibold">*</span>
          </label>
          <FormControl fullWidth>
            <InputLabel id="province-label">Select a Province</InputLabel>
            <Select
              labelId="province-label"
              id="province-select"
              label="Select a Province"
              name="company_province"
              value={formData.company_province}
              onChange={(e) => handleChange("company_province", e.target.value)}
              required
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {provinces.map((province, index) => (
                <MenuItem key={index} value={province}>
                  {province}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-col mt-4 w-full">
          <label htmlFor="company_district" className="text-lg mb-2">
            District <span className="text-red-500 font-semibold">*</span>
          </label>
          <FormControl fullWidth>
            <InputLabel id="district-label">Select a District</InputLabel>
            <Select
              labelId="district-label"
              id="district-select"
              label="Select a District"
              name="company_district"
              value={formData.company_district}
              onChange={(e) => handleChange("company_district", e.target.value)}
              required
              disabled={!formData.company_province}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {filteredDistricts.map((district, index) => (
                <MenuItem key={index} value={district}>
                  {district}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-col mt-4 w-full">
          <label htmlFor="postal_code" className="text-lg mb-2">
            Postal Code <span className="text-red-500 font-semibold">*</span>
          </label>
          <input
            required
            name="postal_code"
            className="w-full p-3 rounded-md border border-gray-300"
            type="number"
            value={formData.postal_code}
            onChange={(e) => handleChange("postal_code", e.target.value)}
            placeholder="Postal Code"
          />
        </div>
      </div>
    </form>
  );
};

export default CompanyAddress;
