import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Avatar,
  LinearProgress,
  Typography,
} from "@mui/material";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmojiPicker from "emoji-picker-react";
import { addComment } from "../../utils/apiFunctions";
import { UserContext } from "../../context/userContext";
import axios from "axios";

const CommentBox = ({ postId, parentCommentId, onCommentAdded }) => {
  const [comment, setComment] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [attachedImage, setAttachedImage] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(""); // Track error state
  const emojiPickerRef = useRef();
  const { user } = useContext(UserContext);

  const cloudName = process.env.REACT_APP_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_BUSINESS_IMAGE_UPLOAD_PRESET;

  const uploadMedia = async (file) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset);

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );
      setMediaUrl(res.data.secure_url);
      setUploading(false);
    } catch (error) {
      console.error("Error uploading media:", error);
      setUploading(false);
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setComment((prevComment) => prevComment + emojiObject.emoji);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAttachedImage(e.target.result);
      };
      reader.readAsDataURL(file);
      uploadMedia(file);
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
    if (error) {
      setError(""); // Clear error when user starts typing
    }
  };

  const handleSubmit = async () => {
    if (!comment.trim()) {
      setError("Please enter a comment");
      return;
    }

    if (comment.trim() || mediaUrl) {
      const formData = {
        user_id: user._id,
        text: comment,
        parent_comment_id: parentCommentId,
        media: mediaUrl,
      };

      try {
        await addComment(postId, formData);
        onCommentAdded();
        setComment("");
        setAttachedImage(null);
        setMediaUrl(null);
        setUploadProgress(0);
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        borderRadius: 2,
        position: "relative",
        marginTop: "20px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={user.profile_picture || ""}
          alt={user.name}
          sx={{ width: 32, height: 32, mr: 2 }}
        />
        <Box sx={{ flex: 1, position: "relative" }}>
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              fontFamily: '"Segoe UI Emoji", "Apple Color Emoji", sans-serif',
              border: error ? "1px solid red" : "1px solid #ddd",
              borderRadius: "20px",
              padding: "5px 10px",
              width: "100%",
            }}
            placeholder="Write a comment..."
            multiline
            value={comment}
            onChange={handleCommentChange}
            required
          />
          {/* {error && (
            <Typography
              variant="caption"
              color="error"
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                mt: 0.5,
                ml: 1,
              }}
            >
              {error}
            </Typography>
          )} */}
        </Box>
        <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
          <InsertEmoticonIcon />
        </IconButton>
        <IconButton component="label">
          <AttachFileIcon />
          <input type="file" hidden onChange={handleFileChange} />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ marginLeft: "10px", borderRadius: "20px" }}
          disabled={uploading}
        >
          Submit
        </Button>
      </Box>
      {showEmojiPicker && (
        <Box
          ref={emojiPickerRef}
          sx={{ zIndex: 1, position: "absolute", top: "50px" }}
        >
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </Box>
      )}
      {attachedImage && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <img
            src={attachedImage}
            alt="Attached"
            style={{ maxHeight: "100px", borderRadius: "10px" }}
          />
        </Box>
      )}
      {uploading && (
        <Box sx={{ width: "100%", mt: 2 }}>
          <Typography variant="body2" color="textSecondary">
            Uploading...
          </Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}
    </Paper>
  );
};

export default CommentBox;
