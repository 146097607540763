import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";
import EditBusinessNavbar from "./EditBusinessNavbar";
import BusinessInfo from "./BusinessInfo";
import CompanyDetails from "./CompanyDetails";
import CompanyAddress from "./CompanyAddress";
import ContactInfo from "./ContactInfo";
import UpdateBusinessBtn from "./UpdateBusinessBtn";
import {
  getBusinessById,
  updateBusiness,
} from "../../../../utils/apiFunctions";
import { useSnackbar } from "notistack";

const EditBusiness = () => {
  const location = useLocation();
  const { post } = location.state || {};
  const [currentState, setCurrentState] = useState("businessInfo");
  const [isMobileView, setIsMobileView] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [business, setBusiness] = useState({});
  const [formData, setFormData] = useState({});
  // const navigate = useNavigate();

  // Fetch business data by ID and set initial form data
  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const item = await getBusinessById(post._id);
        // console.log(item);
        setBusiness(item);

        // Initialize form data with the fetched business data
        const initialFormData = {
          _id: item?._id || "",
          name: item?.name || "",
          location: item?.location || "",
          industry: item?.industry || "",
          category: item?.category || "",
          logo: item?.logo || "",
          postImages: item?.postImages || [],
          video: item?.video || "",
          service_description: item?.service_description || "",
          slogan: item?.slogan || "",
          company_vision: item?.company_vision || "",
          company_mission: item?.company_mission || "",
          company_created_date: item?.company_created_date || "",
          company_address_line_one: item?.company_address_line_one || "",
          company_address_line_two: item?.company_address_line_two || "",
          company_province: item?.company_province || "",
          company_district: item?.company_district || "",
          postal_code: item?.postal_code || "",
          contact_number: item?.contact_number || "",
          website: item?.website || "",
          linkedin: item?.linkedin || "",
          facebook: item?.facebook || "",
          whatsapp: item?.whatsapp || "",
        };

        setFormData(initialFormData);
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };

    fetchBusinessData();
  }, [post._id]);

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (name, files) => {
    if (files.length > 0) {
      if (name === "postImages") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: [...prevData.postImages, ...files],
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: files[0],
        }));
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const isPhoneNumberValid =
      formData.contact_number.length === 9 ||
      formData.contact_number.length === 10 ||
      formData.contact_number.length === 0;
    const isWhatsAppValid =
      formData.whatsapp.length === 9 ||
      formData.whatsapp.length === 10 ||
      formData.whatsapp.length === 0;

    if (!isPhoneNumberValid || !isWhatsAppValid) {
      enqueueSnackbar(
        "Contact number and WhatsApp number must be 9 or 10 digits",
        { variant: "warning" }
      );
      return;
    }

    const hasChanges = Object.keys(formData).some(
      (key) => formData[key] !== business[key]
    );

    if (hasChanges) {
      try {
        await updateBusiness(business._id, formData);
        enqueueSnackbar("Business Details Updated", { variant: "success" });
        // navigate("/business");
        window.location.reload();
      } catch (error) {
        console.error("Error updating business:", error);
        enqueueSnackbar("Business Details Update Failed", { variant: "error" });
      }
    } else {
      enqueueSnackbar("No changes detected", { variant: "info" });
    }
  };

  const countFilledFields = (data) => {
    return Object.values(data).filter((value) => value && value !== null)
      .length;
  };

  const stepsCompleted = countFilledFields(formData);
  const totalSteps = Object.keys(formData).length;

  const renderContent = () => {
    if (isMobileView) {
      return (
        <>
          <h1 className="text-xl mb-2 w-full font-semibold p-2">
            Business Information
          </h1>
          <BusinessInfo
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
          />
          <h1 className="text-xl mb-2 w-full font-semibold p-2">
            Company Information
          </h1>
          <CompanyDetails formData={formData} handleChange={handleChange} />
          <h1 className="text-xl mb-2 w-full font-semibold p-2">
            Company Address
          </h1>
          <CompanyAddress formData={formData} handleChange={handleChange} />
          <h1 className="text-xl mb-2 w-full font-semibold p-2">
            Contact Information
          </h1>
          <ContactInfo formData={formData} handleChange={handleChange} />
        </>
      );
    }

    switch (currentState) {
      case "businessInfo":
        return (
          <BusinessInfo
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
          />
        );
      case "companyDetails":
        return (
          <CompanyDetails formData={formData} handleChange={handleChange} />
        );
      case "companyAddress":
        return (
          <CompanyAddress formData={formData} handleChange={handleChange} />
        );
      case "contactInfo":
        return <ContactInfo formData={formData} handleChange={handleChange} />;
      default:
        return (
          <BusinessInfo
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
          />
        );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!post) {
    return <div>No post data available</div>;
  }

  return (
    <div className="w-full flex flex-col mb-10">
      {!isMobileView && (
        <div className="w-full flex justify-between items-center">
          <h1 className="text-3xl font-semibold">Edit Business Profile</h1>
          <div className="w-1/3 flex flex-col items-start mb-7">
            <h3 className="font-medium">Business Completion</h3>
            <div className="w-full flex items-center justify-between">
              <LinearProgress
                className="w-full rounded-lg mr-6 py-1"
                variant="determinate"
                value={(stepsCompleted / totalSteps) * 100}
              />
              <h1 className="text-gray-500">
                {Math.floor((stepsCompleted / totalSteps) * 100)}%
              </h1>
            </div>
          </div>
        </div>
      )}

      <Box className="w-full flex justify-between bg-white p-2 rounded-xl shadow-2xl mb-16">
        {!isMobileView && (
          <div className="w-1/4">
            <EditBusinessNavbar setCurrentState={setCurrentState} />
          </div>
        )}
        <div className={isMobileView ? "w-full" : "w-3/4"}>
          {renderContent()}
          <UpdateBusinessBtn handleUpdate={handleUpdate} />
        </div>
      </Box>
    </div>
  );
};

export default EditBusiness;
