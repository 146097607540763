import React from "react";
import { useNavigate } from "react-router-dom";
const UpdateProfileBtn = ({ handleUpdate }) => {
  const navigate = useNavigate();

  return (
    <div className="flex mb-10 ">
      <button
        onClick={() => navigate("/profile")}
        className="bg-white w-1/2 md:w-1/4 border-gray-300 border text-black px-10 py-2  rounded"
      >
        Cancel
      </button>
      <button
        onClick={handleUpdate}
        className="bg-blue-500 w-1/2 md:w-1/4 text-white px-10 py-2  rounded ml-5"
      >
        Update
      </button>
    </div>
  );
};

export default UpdateProfileBtn;
