// src/components/NewBusiness/NewBusiness.js

import React, { useState } from "react";
import OldBusiness from "./OldBusiness";
import CameraAltOutlined from "@mui/icons-material/CameraAltOutlined";
import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";
import { createBusiness } from "../../../utils/apiFunctions";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import { useDropzone } from "react-dropzone";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import axios from "axios";
import { format } from "date-fns";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// New Component: MapDialog
const MapDialog = ({ open, handleClose, onSelectLocation }) => {
  const [position, setPosition] = useState([6.9271, 79.8612]); // Default coordinates (Colombo)
  const defaultIcon = L.icon({
    iconUrl: "https://unpkg.com/leaflet/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
  const MapEvents = () => {
    useMapEvents({
      click(e) {
        setPosition([e.latlng.lat, e.latlng.lng]);
      },
    });
    return null;
  };

  const handleSelect = () => {
    onSelectLocation(position);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <div className="p-4">
        <h2 className="text-lg mb-4">Select Location</h2>
        <MapContainer
          center={position}
          zoom={13}
          style={{ height: "400px", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <MapEvents />
          <Marker position={position} icon={defaultIcon} />
        </MapContainer>
        <div className="flex justify-end mt-4">
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSelect}
            variant="contained"
            color="primary"
            className="ml-2"
          >
            Select
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const NewBusiness = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState("createBusiness");
  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreviews, setImagePreviews] = useState();
  const [videoPreview, setVideoPreview] = useState(null);
  const [logoProgress, setLogoProgress] = useState(0);
  const [imageProgress, setImageProgress] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [openMapDialog, setOpenMapDialog] = useState(false);

  const ButtonHandle = (currentState) => {
    setState(currentState);
  };

  const categories = [
    "Government Business",
    "Group Business",
    "Private Business",
  ];

  const industries = [
    "Manufacturing",
    "Agriculture",
    "Management",
    "Construction",
    "Technology",
    "Health Care",
    "Food Industry",
    "Education",
    "Entertainment",
    "Clothing Industry",
  ];

  const [formData, setFormData] = useState({
    user_id: localStorage.getItem("token"),
    name: "",
    location: "",
    industry: "",
    category: "",
    logo: "",
    postImages: [],
    video: "",
    post_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
  });

  const cloudName = process.env.REACT_APP_CLOUD_NAME;
  const uploadLogoPreset = process.env.REACT_APP_BUSINESS_LOGO_UPLOAD_PRESET;
  const uploadVideoPreset = process.env.REACT_APP_BUSINESS_VIDEO_UPLOAD_PRESET;
  const uploadImagesPreset = process.env.REACT_APP_BUSINESS_IMAGE_UPLOAD_PRESET;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const uploadLogo = async (file) => {
    const formDataLogos = new FormData();
    formDataLogos.append("file", file);
    formDataLogos.append("upload_preset", uploadLogoPreset);
    formDataLogos.append("folder", "business_logos");

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formDataLogos,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setLogoProgress(progress);
          },
        }
      );
      setFormData((prevState) => ({
        ...prevState,
        logo: res.data.secure_url,
      }));
      setImagePreview(res.data.secure_url);
    } catch (error) {
      console.error("error uploading logo image :", error);
    }
  };

  const uploadVideo = async (files) => {
    const formDataMedia = new FormData();
    formDataMedia.append("file", files[0]);
    formDataMedia.append("upload_preset", uploadVideoPreset);
    formDataMedia.append("folder", "business_videos");

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/video/upload`,
        formDataMedia,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setVideoProgress(progress);
          },
        }
      );
      setFormData((prevState) => ({
        ...prevState,
        video: res.data.secure_url,
      }));
      setVideoPreview(res.data.secure_url);
    } catch (error) {
      console.error("error uploading video :", error);
    }
  };

  const uploadImages = async (files) => {
    const formDataMedia = new FormData();
    files.forEach((file) => {
      formDataMedia.append("file", file);
      formDataMedia.append("upload_preset", uploadImagesPreset);
      formDataMedia.append("folder", "business_images");
    });

    try {
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formDataMedia,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setImageProgress(progress);
          },
        }
      );
      setFormData((prevState) => ({
        ...prevState,
        postImages: res.data.secure_url,
      }));
      setImagePreviews(res.data.secure_url);
    } catch (error) {
      console.error("error uploading images :", error);
    }
  };

  const createBusinessHandler = async (e) => {
    e.preventDefault();
    try {
      await createBusiness(formData);
      window.location.reload();
      enqueueSnackbar("New Business Created", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error Creating Business", { variant: "error" });
      console.log("Error creating business: ", error);
    }
  };

  const openMap = () => {
    setOpenMapDialog(true);
  };

  const selectLocation = (coords) => {
    setFormData((prevState) => ({
      ...prevState,
      location: `${coords[0]},${coords[1]}`,
    }));
  };

  const DropzoneComponent = ({ name, accept, filesLimit, onDrop }) => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept,
      maxFiles: filesLimit,
      multiple: filesLimit > 1,
    });

    return (
      <div
        {...getRootProps({ className: "dropzone" })}
        className="border border-dashed border-gray-300 p-4 text-center w-full "
      >
        <input {...getInputProps()} />
        <p>Drag & drop files here, or click to select files</p>
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col pt-3 md:pl-2">
      <div className="w-full md:pr-10">
        <h1 className="text-3xl font-semibold">My Business</h1>
        <div className="mt-6 lg:ml-4 w-full flex bg-gray-200 md:w-2/3 items-center rounded-full justify-evenly lg:p-1 p-1 ">
          <button
            onClick={() => ButtonHandle("createBusiness")}
            className={`w-1/2 flex items-center justify-center bg-gray-200 text-gray-800 font-medium cursor-pointer p-3 text-sm md:text-base rounded-full ${
              state === "createBusiness" && "bg-white text-blue-700 shadow-md"
            }`}
          >
            <PostAddOutlinedIcon
              style={{ color: state === "createBusiness" ? "blue" : "inherit" }}
            />
            <span className="ml-3 text-sm md:text-base">Create One</span>
          </button>
          <button
            onClick={() => ButtonHandle("oldBusiness")}
            className={`w-1/2 flex items-center justify-center  bg-gray-200 text-gray-800 font-medium cursor-pointer p-3 text-sm md:text-base rounded-full ml-1 ${
              state === "oldBusiness" && "bg-white shadow-md text-blue-700"
            }`}
          >
            <ViewAgendaIcon
              style={{ color: state === "oldBusiness" ? "blue" : "inherit" }}
            />
            <span className="ml-3 text-sm md:text-base">My Business</span>
          </button>
        </div>
      </div>
      {state === "createBusiness" ? (
        <form
          onSubmit={createBusinessHandler}
          className="mt-8 w-full mx-auto p-5 bg-white flex flex-col shadow-xl justify-between rounded-xl"
        >
          <h1 className="text-xl font-semibold">Create New Business</h1>
          <div className="flex w-full flex-col justify-between ">
            <div className="flex w-full flex-row-reverse justify-between">
              <div className="w-1/2 flex flex-col md:items-center mt-6 items-end mr-3">
                <label className="text-sm md:text-base" htmlFor="logo">
                  Business Logo
                </label>
                <label className="font-bold" htmlFor="logo">
                  <div className="w-16 h-16 bg-gray-300 border-black border mt-4 flex items-center justify-center p-2 cursor-pointer rounded-full">
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="logo"
                        className="rounded-full w-full h-full"
                      />
                    ) : (
                      <CameraAltOutlined style={{ fontSize: "30px" }} />
                    )}
                  </div>
                </label>
                <input
                  id="logo"
                  type="file"
                  name="logo"
                  onChange={(e) => uploadLogo(e.target.files[0])}
                  hidden
                />
                {logoProgress > 0 && (
                  <div className="w-full bg-gray-200 rounded-full mt-2">
                    <div
                      className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0 leading-none rounded-full"
                      style={{ width: `${logoProgress}%` }}
                    >
                      {logoProgress}%
                    </div>
                  </div>
                )}
              </div>
              <div className="flex w-full flex-col justify-between mb-4">
                <div className="flex flex-col mt-6 w-full">
                  <label htmlFor="businessName" className="text-lg mb-2">
                    Business Name
                  </label>
                  <input
                    value={formData.name}
                    onChange={handleChange}
                    required
                    name="name"
                    className="w-full p-3 rounded-md border border-gray-300"
                    type="text"
                    placeholder="Business Name"
                  />
                </div>
                <div className="flex flex-col mt-6 w-full mb-6">
                  <label className="text-lg mb-2">Industry</label>
                  <FormControl fullWidth>
                    <InputLabel id="industry-label">
                      Select an Industry
                    </InputLabel>
                    <Select
                      labelId="industry-label"
                      id="industry-select"
                      value={formData.industry}
                      label="Select an industry"
                      onChange={handleChange}
                      name="industry"
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {industries.map((industry, index) => (
                        <MenuItem key={index} value={industry}>
                          {industry}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="flex flex-col mt-6 w-full mb-6">
                  <label className="text-lg mb-2">Type of Business</label>
                  <FormControl fullWidth>
                    <InputLabel id="category-label">
                      Select a Category
                    </InputLabel>
                    <Select
                      labelId="category-label"
                      id="category-select"
                      value={formData.category}
                      label="Select a category"
                      onChange={handleChange}
                      name="category"
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {categories.map((category, index) => (
                        <MenuItem key={index} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="flex flex-col mt-6 w-full">
                  <label htmlFor="location" className="text-lg mb-2">
                    Location
                  </label>
                  <FormControl fullWidth variant="outlined">
                    <Button
                      variant="outlined"
                      endIcon={<LocationOnOutlined />}
                      onClick={openMap}
                      style={{
                        justifyContent: "flex-start",
                        padding: "10px 14px",
                        textTransform: "none",
                      }}
                    >
                      Select Location
                    </Button>
                    {formData.location && (
                      <div className="mt-2">
                        <p>Coordinates: {formData.location}</p>
                      </div>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>
            <label className="text-lg mb-2">Video</label>
            <div className="flex flex-col w-full mb-6 items-center justify-center border cursor-pointer border-blue-500 border-dashed rounded-2xl">
              <DropzoneComponent
                name="video"
                accept="video/*"
                filesLimit={1}
                onDrop={(files) => uploadVideo(files)}
              />
              {videoProgress > 0 && (
                <div className="w-full bg-gray-200 rounded-full mt-2">
                  <div
                    className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                    style={{ width: `${videoProgress}%` }}
                  >
                    {videoProgress}%
                  </div>
                </div>
              )}
            </div>
            {videoPreview && (
              <div className="flex justify-center mb-6">
                <video
                  src={videoPreview}
                  controls
                  className="w-full  rounded-lg"
                />
              </div>
            )}
            <label className="text-lg mb-2">Image (Max 5)</label>
            <div className="flex flex-col w-full mb-6 items-center justify-center cursor-pointer border border-blue-500 border-dashed rounded-2xl">
              <DropzoneComponent
                name="postImages"
                accept="image/*"
                filesLimit={5}
                onDrop={(files) => uploadImages(files)}
              />
              {imageProgress > 0 && (
                <div className="w-full bg-gray-200 rounded-full mt-2">
                  <div
                    className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                    style={{ width: `${imageProgress}%` }}
                  >
                    {imageProgress}%
                  </div>
                </div>
              )}
            </div>
            {imagePreviews && (
              <div className="flex flex-wrap justify-center mb-6">
                <img
                  src={imagePreviews}
                  alt={imagePreviews}
                  className="w-32 h-32 m-2 object-cover rounded-lg"
                />
              </div>
            )}
            <div>
              <input
                type="submit"
                value="Create New Business"
                className="bg-blue-600 mb-6 p-3 rounded-lg px-16 self-end text-white cursor-pointer"
              />
            </div>
          </div>
        </form>
      ) : (
        <div className="w-full flex flex-col mt-10">
          <OldBusiness />
        </div>
      )}
      <MapDialog
        open={openMapDialog}
        handleClose={() => setOpenMapDialog(false)}
        onSelectLocation={selectLocation}
      />
    </div>
  );
};

export default NewBusiness;
