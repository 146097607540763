import React, { useContext, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { rateBusiness } from "../../utils/apiFunctions";
import { UserContext } from "../../context/userContext";

const RatingBox = ({ setIsRatingDialogOpen, postId }) => {
  const [ratings, setRatings] = useState({
    companyQuality: 0,
    customerService: 0,
    productQuality: 0,
    afterService: 0,
    loremIpsum: 0,
  });
  const [selectedRating, setSelectedRating] = useState(0);
  const { user } = useContext(UserContext);

  // Function to calculate the total rating
  const calculateTotalRating = (updatedRatings) => {
    const total = Object.values(updatedRatings).reduce(
      (sum, value) => sum + value,
      0
    );

    setSelectedRating(total);
  };

  const handleRatingClick = (category, rating) => {
    setRatings((prevRatings) => {
      const updatedRatings = {
        ...prevRatings,
        [category]: rating,
      };
      calculateTotalRating(updatedRatings);
      return updatedRatings;
    });
  };

  // Submit the rating to the backend
  const handleSubmit = async () => {
    if (!user) {
      console.error("User must be logged in to submit a rating.");
      return;
    }
    try {
      const formData = {
        user_id: user._id,
        rating: selectedRating, // Send the selected rating (sum of selected stars)
      };
      const response = await rateBusiness(postId, formData);
      console.log("Rating submitted:", response);

      setIsRatingDialogOpen(false); // Close the rating dialog
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: "16px", sm: "30px" },
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        width: { xs: "90%", sm: "600px" },
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: "1px",
          fontWeight: "bold",
          fontSize: { xs: "1.5rem", sm: "2rem" },
        }}
      >
        Send Us Some Rating!
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          marginBottom: "24px",
          color: "#666",
          fontSize: { xs: "0.9rem", sm: "1rem" },
        }}
      >
        Click star to rate
      </Typography>
      {[
        "companyQuality",
        "customerService",
        "productQuality",
        "afterService",
        "loremIpsum",
      ].map((category) => (
        <Box
          key={category}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "space-between", sm: "space-between" },
            marginBottom: "8px",
            paddingX: { xs: 0, sm: "40px" },
          }}
        >
          <Typography
            sx={{
              marginRight: "20px",
              fontSize: { xs: "14px", sm: "16px" },
              textTransform: 'capitalize'
            }}
          >
            {category.replace(/([A-Z])/g, " $1").trim()}
          </Typography>
          <Box>
            {[1, 2, 3, 4, 5].map((star) => (
              <IconButton
                key={star}
                onClick={() => handleRatingClick(category, star)}
              >
                <StarIcon
                  sx={{
                    color: ratings[category] >= star ? "yellow" : "gray",
                    fontSize: { xs: "24px", sm: "30px" },
                  }}
                />
              </IconButton>
            ))}
          </Box>
        </Box>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{
          marginTop: "24px",
          padding: "10px 20px",
          backgroundColor: "#007BFF",
          "&:hover": {
            backgroundColor: "#0056b3",
          },
          maxWidth: "210px",
          marginX: "auto",
        }}
      >
        Submit Your Rating
      </Button>
    </Box>
  );
};

export default RatingBox;
