import React from 'react';

const BusinessSvg = ({ color }) => {
    return (
        <svg
            width="20"
            height="17"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.5 20C3.45833 20 2.57292 19.6354 1.84375 18.9063C1.11458 18.1771 0.75 17.2917 0.75 16.25C0.75 15.2083 1.11458 14.3229 1.84375 13.5938C2.57292 12.8646 3.45833 12.5 4.5 12.5C5.54167 12.5 6.42708 12.8646 7.15625 13.5938C7.88542 14.3229 8.25 15.2083 8.25 16.25C8.25 17.2917 7.88542 18.1771 7.15625 18.9063C6.42708 19.6354 5.54167 20 4.5 20ZM19.5 20C18.4583 20 17.5729 19.6354 16.8438 18.9063C16.1146 18.1771 15.75 17.2917 15.75 16.25C15.75 15.2083 16.1146 14.3229 16.8438 13.5938C17.5729 12.8646 18.4583 12.5 19.5 12.5C20.5417 12.5 21.4271 12.8646 22.1562 13.5938C22.8854 14.3229 23.25 15.2083 23.25 16.25C23.25 17.2917 22.8854 18.1771 22.1562 18.9063C21.4271 19.6354 20.5417 20 19.5 20ZM12 7.5C10.9583 7.5 10.0729 7.13542 9.34375 6.40625C8.61458 5.67708 8.25 4.79167 8.25 3.75C8.25 2.70833 8.61458 1.82292 9.34375 1.09375C10.0729 0.364583 10.9583 0 12 0C13.0417 0 13.9271 0.364583 14.6562 1.09375C15.3854 1.82292 15.75 2.70833 15.75 3.75C15.75 4.79167 15.3854 5.67708 14.6562 6.40625C13.9271 7.13542 13.0417 7.5 12 7.5Z"
                fill={color}
            />
        </svg>
    );
};

export default BusinessSvg;
