import React, { useContext } from "react";
import { UserContext } from "../../context/userContext.jsx";
import { BusinessContext } from "../../context/businessContext.jsx";
import profileImg from "../../assets/dummyProfile.jpg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { PrivacyTip } from "@mui/icons-material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faColumns, faGift } from "@fortawesome/free-solid-svg-icons";

const LeftSidebar = () => {
  const { user, isMobileView } = useContext(UserContext);
  const { userBusiness } = useContext(BusinessContext);
  const navigate = useNavigate();
  const location = useLocation();

  if (!user || !userBusiness) {
    return (
      <div className="bg-white hidden lg:block w-1/4 rounded-lg h-48 sm:h-64 md:h-96 shadow-lg border "></div>
    );
  }

  const profileImageUrl = user.profile_picture
    ? `${user.profile_picture}`
    : profileImg;

  const currentPath = location.pathname; // Store current pathname
  const isActive = (path) => {
    return currentPath.startsWith(path) ? "bg-[#F2F7FF]" : ""; // If the path starts with a given string, apply the highlight color
  };

  if (
    location.pathname === "/profile" ||
    location.pathname === "/profile/edit" ||
    location.pathname === "/business/edit" ||
    location.pathname === "/promotion"
  ) {
    return (
      !isMobileView && (
        <div className="hidden md:block w-1/3 lg:w-1/4 p-4">
          <div className="bg-white p-4 shadow-2xl rounded-xl">
            <div
              style={{ backgroundColor: "#F2F6FB" }}
              className="rounded-lg border flex w-full justify-between p-2 gap-4"
            >
              <img
                src={profileImageUrl}
                alt="Profile"
                className="rounded-full w-12 h-12 sm:w-16 sm:h-16 md:w-16 md:h-16 border-4 border-white"
              />
              <div className="flex flex-col items-start justify-center w-full overflow-hidden">
                <h2 className="text-start text-black text-sm font-bold w-full">
                  {user.first_name} {user.last_name}
                </h2>
                <p className="text-gray-500 text-start text-xs w-full">
                  {user.email}
                </p>
              </div>
            </div>

            {/* Navigation Links */}
            <div className="mt-8">
              <Link to="/">
                <div
                  className={`text-black hover:bg-gray-100 px-4 py-3 rounded flex items-center justify-start cursor-pointer`}
                >
                  <HomeOutlinedIcon className="mr-3 text-lg" />
                  <p className="text-sm font-medium">Home</p>
                </div>
              </Link>
              <Link to="/profile">
                <div
                  className={`text-black hover:bg-gray-100 px-4 py-3 rounded flex items-center justify-start cursor-pointer ${isActive(
                    "/profile"
                  )}`}
                >
                  <PersonOutlineOutlinedIcon className="mr-3 text-lg" />
                  <p className="text-sm font-medium">Profile</p>
                </div>
              </Link>
              <Link to="/business">
                <div
                  className={`text-black hover:bg-gray-100 px-4 py-3 rounded flex items-center justify-start cursor-pointer ${isActive(
                    "/business"
                  )}`}
                >
                  <ChangeHistoryOutlinedIcon className="mr-3 text-lg" />
                  <p className="text-sm font-medium">My Business</p>
                </div>
              </Link>
              <Link to="/promotion">
                <div
                  className={`text-black hover:bg-gray-100 px-4 py-3 rounded flex items-center justify-start cursor-pointer ${isActive(
                    "/promotion"
                  )}`}
                >
                  <FontAwesomeIcon icon={faGift} className="mr-3 ml-1.5 text-lg" />
                  <p className="text-sm font-medium">Promotion</p>
                </div>
              </Link>
              <Link to="/notification">
                <div
                  className={`text-black hover:bg-gray-100 px-4 py-3 rounded flex items-center cursor-pointer ${isActive(
                    "/notification"
                  )}`}
                >
                  <NotificationsNoneOutlinedIcon className="mr-3 text-lg" />
                  <p className="text-sm font-medium">Notifications</p>
                </div>
              </Link>
              <Link to="/settings">
                <div
                  className={`text-black hover:bg-gray-100 px-4 py-3 rounded flex items-center cursor-pointer ${isActive(
                    "/settings"
                  )}`}
                >
                  <SettingsOutlinedIcon className="mr-3 text-lg" />
                  <p className="text-sm font-medium">Settings</p>
                </div>
              </Link>
              <Link to="/privacy">
                <div
                  className={`text-black hover:bg-gray-100 px-4 py-3 rounded flex items-center cursor-pointer ${isActive(
                    "/privacy"
                  )}`}
                >
                  <PrivacyTip className="mr-3 text-lg" />
                  <p className="text-sm font-medium">Privacy and Data</p>
                </div>
              </Link>
              <Link to="/faq">
                <div
                  className={`text-black hover:bg-gray-100 px-4 py-3 rounded flex items-center cursor-pointer ${isActive(
                    "/faq"
                  )}`}
                >
                  <FontAwesomeIcon icon={faColumns} className="mr-3 ml-1.5 text-lg" />
                  <p className="text-sm font-medium">FAQ</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    !isMobileView && (
      <div className="hidden md:block w-1/4 p-4">
        <div className="bg-white rounded-lg shadow-lg border min-h-[400px] max-h-[450px] flex flex-col items-center text-center">
          <div
            alt="Cover"
            className="w-full h-24 sm:h-24 md:h-24 rounded-t-lg object-cover bg-[#E8EAED]"
          />
          <div className="flex flex-col items-center -mt-12 sm:-mt-16 md:-mt-20 p-4">
            <img
              src={profileImageUrl}
              alt="Profile"
              className="rounded-full w-24 h-24 sm:w-24 sm:h-24 md:w-24 md:h-24 border-4 border-white"
            />
            <h2
              onClick={() => navigate("/profile")}
              className="mt-4 cursor-pointer text-black text-sm sm:text-xl md:text-xl font-bold"
            >
              {user.first_name} {user.last_name}
            </h2>
            <p
              style={{ color: "#BDBEBE" }}
              className="text-xs font-semibold mt-1 sm:text-sm md:text-base"
            >
              {user.email}
            </p>
            <p
              className="text-gray-800 text-xs mt-1 sm:text-sm md:text-base"
              style={{ color: "#0F1419" }}
            >
              {user.about}
            </p>
          </div>
          <div className="flex justify-between w-full border-t border-b py-4">
            <div className="text-center w-1/2 border-r">
              <div className="font-bold text-xl sm:text-2xl">
                {userBusiness.length}
              </div>
              <p className="text-gray-600 text-xs sm:text-sm">Posts</p>
            </div>
            <div className="text-center w-1/2">
              <div className="font-bold text-xl sm:text-2xl">200</div>
              <p className="text-gray-600 text-xs sm:text-sm">Followers</p>
            </div>
          </div>
          <div className="flex justify-between w-full border-t border-b py-4 pb-7"></div>
        </div>
      </div>
    )
  );
};

export default LeftSidebar;
