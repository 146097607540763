import React from "react";
import UserBar from "./UserBar";
import Searchbar from "./Searchbar";
import HomeContent from "./HomeContent";
import PromotionContainer from "./PromotionContainer";

const Home = () => {
  return (
    <div className="flex flex-col items-center w-full px-3 ">
      <UserBar />
      <Searchbar />
      <PromotionContainer />
      <HomeContent />
    </div>
  );
};

export default Home;
