import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, LinearProgress } from "@mui/material";
import EditProfileNavbar from "./EditProfileNavbar";
import PersonalInfo from "./PersonalInfo";
import EducationInfo from "./EducationInfo";
import ProfessionalInfo from "./ProfessionalInfo";
import AccountInfo from "./AccountInfo";
import SkillInfo from "./SkillInfo";
import UpdateProfileBtn from "./UpdateProfileBtn";
import { updateUser } from "../../../../utils/apiFunctions";
import { UserContext } from "../../../../context/userContext";
import { useSnackbar } from "notistack";

const EditProfile = () => {
  const { userId, user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [currentState, setCurrentState] = useState("personalInfo");
  const [isMobileView, setIsMobileView] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [completion, setCompletion] = useState(0); // Profile completion state

  // Default form data structure
  const defaultFormData = useMemo(() => ({
    profile_picture: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    address: "",
    nic: "",
    dob: "",
    about: "",
    job_title: "",
    company: "",
    company_description: "",
    education: [
      {
        school: "",
        degree: "",
        field_of_study: "",
        grade: "",
        school_start_date: "",
        school_end_date: "",
      },
    ],
    skills: [
      {
        skill: "",
        where_used_the_skill: "",
        certificate_name: "",
        certificate_organization: "",
        certificate_issue_date: "",
        certificate_expire_date: "",
        certificate_url: "",
      },
    ],
    website: "",
    linkedin: "",
    facebook: "",
    whatsapp: "",
  }), []);

  // Set formData state using defaultFormData as the initial value
  const [formData, setFormData] = useState(defaultFormData);

  // Update formData when user data is fetched
  useEffect(() => {
    if (user && !isUpdated) {
      setFormData({
        ...defaultFormData,
        ...user,
        education: user.education?.length ? user.education : defaultFormData.education,
        skills: user.skills?.length ? user.skills : defaultFormData.skills,
      });
    }
  }, [user, isUpdated, defaultFormData]);

  // Calculate profile completion dynamically
  const calculateCompletion = (data) => {
    let filledFields = 0;
    let totalFields = 0;

    const countFields = (obj) => {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item) => countFields(item));
        } else {
          totalFields++;
          if (obj[key]) filledFields++;
        }
      }
    };

    countFields(data);
    return (filledFields / totalFields) * 100;
  };

  useEffect(() => {
    setCompletion(calculateCompletion(formData)); // Set initial completion
  }, [formData]);

  // Handle form change updates
  const handleChange = (section, indexOrName, nameOrValue, value = null) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };

      if (Array.isArray(updatedData[section])) {
        const updatedSection = [...updatedData[section]];

        if (typeof indexOrName === "number") {
          updatedSection[indexOrName][nameOrValue] = value;
        } else if (indexOrName === "add") {
          updatedSection.push(nameOrValue);
        }

        updatedData[section] = updatedSection;
      } else {
        updatedData[section] = indexOrName;
      }

      setCompletion(calculateCompletion(updatedData)); // Recalculate completion on change
      return updatedData;
    });
  };

  // Validate form data
  const validateFormData = () => {
    if (formData.email && !formData.email.includes("@")) {
      enqueueSnackbar("Invalid email format", { variant: "warning" });
      return false;
    }

    if (formData.mobile && (formData.mobile.length < 9 || formData.mobile.length > 10)) {
      enqueueSnackbar("Mobile number is not complete", { variant: "warning" });
      return false;
    }

    return true;
  };

  // Check if form data has been changed
  const isFormDataChanged = () => {
    return Object.keys(formData).some((key) => {
      if (Array.isArray(formData[key])) {
        return JSON.stringify(formData[key]) !== JSON.stringify(user[key]);
      }
      return formData[key] !== user[key];
    });
  };

  // Handle form update
  const handleUpdate = async (e) => {
    e.preventDefault();

    // Check if any data has been changed
    if (!isFormDataChanged()) {
      enqueueSnackbar("No changes detected", { variant: "info" });
      return;
    }

    // Allow sending null, empty, or undefined values
    const cleanFormData = JSON.parse(
      JSON.stringify(formData, (key, value) => (value === undefined || value === null) ? "" : value) // Convert undefined and null to empty string
    );

    try {
      // Validate the form before sending
      if (!validateFormData()) return;

      // Send the cleanFormData with empty or null fields
      await updateUser(userId, cleanFormData);
      enqueueSnackbar("User Details Updated", { variant: "success" });
      setIsUpdated(true);
      setCompletion(calculateCompletion(cleanFormData)); // Update completion after saving
    } catch (error) {
      console.error("Error updating user:", error);
      enqueueSnackbar("User Details Update Failed", { variant: "error" });
    }
  };

  // Handle window resize for mobile view
  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Render form content based on the current state
  const renderContent = () => {
    const components = {
      personalInfo: <PersonalInfo formData={formData} handleChange={handleChange} handleUpdate={handleUpdate} />,
      professionalInfo: <ProfessionalInfo formData={formData} handleChange={handleChange} handleUpdate={handleUpdate} />,
      educationInfo: <EducationInfo formData={formData} handleChange={handleChange} handleUpdate={handleUpdate} />,
      accountInfo: <AccountInfo formData={formData} handleChange={handleChange} handleUpdate={handleUpdate} />,
      skillInfo: <SkillInfo formData={formData} handleChange={handleChange} handleUpdate={handleUpdate} />,
    };

    if (isMobileView) {
      return (
        <>
          <PersonalInfo formData={formData} handleChange={handleChange} />
          <ProfessionalInfo formData={formData} handleChange={handleChange} />
          <EducationInfo formData={formData} handleChange={handleChange} />
          <AccountInfo formData={formData} handleChange={handleChange} />
          <SkillInfo formData={formData} handleChange={handleChange} handleUpdate={handleUpdate} />
          <UpdateProfileBtn handleUpdate={handleUpdate} />
        </>
      );
    }

    return components[currentState] || components["personalInfo"];
  };

  return (
    <div className="w-full flex flex-col md:pl-2 mb-10">
      {!isMobileView && (
        <div className="w-full flex justify-between items-center">
          <h1 className="text-3xl font-semibold">Edit Profile</h1>
          <div className="w-full md:w-1/3 flex flex-col items-start mb-7">
            <h3 className="font-medium">Profile Completion</h3>
            <div className="w-full flex items-center justify-between">
              <LinearProgress
                className="w-full rounded-lg mr-6 py-1"
                variant="determinate"
                value={completion}
              />
              <h1 className="text-gray-500">{Math.floor(completion)}%</h1>
            </div>
          </div>
        </div>
      )}

      <Box className="w-full flex justify-between bg-white p-2 rounded-xl shadow-2xl mb-16">
        {!isMobileView && (
          <div className="w-1/4">
            <EditProfileNavbar setCurrentState={setCurrentState} />
          </div>
        )}
        <div className={isMobileView ? "w-full" : "w-3/4"}>{renderContent()}</div>
      </Box>
    </div>
  );
};

export default EditProfile;
