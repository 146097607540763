import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/userContext";
import UpdateProfileBtn from "./UpdateProfileBtn";

const ProfessionalInfo = ({ formData, handleChange ,handleUpdate}) => {
  const { user } = useContext(UserContext);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    user && (
      <div className="w-full flex py-7 flex-col items">
        {isMobileView && (
          <h1 className="text-xl mb-2 w-full font-semibold p-2">
            Professional Information
          </h1>
        )}
        <div className="w-full max-w-3xl">
          <div className="flex flex-col mb-4">
            <div className="flex space-x-4 md:space-x-0 mb-4 md:flex-col">
              <div className="w-2/3 mb-4 md:mb-10">
                <label className="block text-gray-500">Job Title</label>
                <input
                  type="text"
                  placeholder="Enter your job title"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={formData.job_title}
                  onChange={(e) => handleChange("job_title", e.target.value)}
                />
              </div>
              <div className="w-2/3 mb-4 md:mb-10">
                <label className="block text-gray-500">Company</label>
                <input
                  type="text"
                  placeholder="Company name"
                  className="w-full border border-gray-300 p-2 rounded"
                  value={formData.company}
                  onChange={(e) => handleChange("company", e.target.value)}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-500">Description</label>
              <textarea
                value={formData.company_description}
                onChange={(e) =>
                  handleChange(
                    "company_description",

                    e.target.value
                  )
                }
                className="w-full border border-gray-300 p-2 rounded h-24"
              ></textarea>
            </div>
            {!isMobileView && <UpdateProfileBtn handleUpdate={handleUpdate}  />}
          </div>
        </div>
      </div>
    )
  );
};

export default ProfessionalInfo;
