import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginSignup from "./pages/auth/LoginSignup";
import Header from "./components/navigation/Header";
import LeftSidebar from "./components/navigation/LeftSidebar";
import RightSidebar from "./components/navigation/RightSidebar";
import Home from "./pages/main/Home/Home";
import NewBusiness from "./pages/main/Business/NewBusiness";
import "./App.css";
import MyProfile from "./pages/main/Profile/MyProfile";
import EditProfile from "./pages/main/Profile/EditProfile/EditProfile";
import EditBusiness from "./pages/main/Business/EditBusiness/EditBusiness";
import Notification from "./pages/main/Notification/Notification";
import Settings from "./pages/main/Settings/Settings";
import PrivacyNData from "./pages/main/Privacy&Data/Privacy&Data";
import FAQ from "./pages/main/FAQ/FAQ";
import PromotionScreen from "./pages/main/Promotion/PromotionScreen";

const DefaultLayout = ({ children }) => {
  return (
    <div className="flex flex-col w-full min-h-screen">
      <Header />
      <div className="flex flex-row w-full pt-2 px-4 md:px-1">
        <LeftSidebar />
        <div
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
          className="flex-1 w-full md:w-2/4 h-[calc(100vh)] scroll overflow-y-auto">
          {children}
        </div>
        <RightSidebar />
      </div>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/signup" element={<LoginSignup />} />
          <Route
            path="/"
            element={
              <DefaultLayout>
                <Home />
              </DefaultLayout>
            }
          />
          <Route
            path="/business"
            element={
              <DefaultLayout>
                <NewBusiness />
              </DefaultLayout>
            }
          />
          <Route
            path="/notification"
            element={
              <DefaultLayout>
                <Notification />
              </DefaultLayout>
            }
          />
          <Route
            path="/settings"
            element={
              <DefaultLayout>
                <Settings />
              </DefaultLayout>
            }
          />
          <Route
            path="/privacy"
            element={
              <DefaultLayout>
                <PrivacyNData />
              </DefaultLayout>
            }
          />
          <Route
            path="/faq"
            element={
              <DefaultLayout>
                <FAQ />
              </DefaultLayout>
            }
          />
          <Route
            path="/profile"
            element={
              <DefaultLayout>
                <MyProfile />
              </DefaultLayout>
            }
          />
          <Route
            path="/profile/edit"
            element={
              <DefaultLayout>
                <EditProfile />
              </DefaultLayout>
            }
          />
          <Route
            path="/business/edit"
            element={
              <DefaultLayout>
                <EditBusiness />
              </DefaultLayout>
            }
          />
          <Route
            path="/promotion"
            element={
              <DefaultLayout>
                <PromotionScreen />
              </DefaultLayout>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
