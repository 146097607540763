// src/components/RightSidebar.js
import React from "react";
import { useLocation } from "react-router-dom";
// import { Adsense } from "@ctrl/react-adsense";

const RightSidebar = () => {
  const location = useLocation();

  // Hide the sidebar on certain routes
  if (
    location.pathname === "/profile/edit" ||
    location.pathname === "/business/edit" ||
    location.pathname === "/promotion"
  ) {
    return null;
  }

  return (
    <div className="bg-white lg:block mt-3 ml-3 hidden rounded-lg w-1/4 h-48 sm:h-64 md:h-96 min-h-[400px] max-h-[450px] shadow-lg border flex-col items-center text-center">
      {/* <Adsense
        className="adsbygoogle"
        style={{ display: "block" }}
        client="ca-pub-2524663437008448"
        slot="4223204743"
        format="auto" // Ad format
        responsive="true" // Responsive
      /> */}
    </div>
  );
};

export default RightSidebar;
